import { useEffect, EffectCallback, useRef, useState, useCallback } from "react"
import { Optional } from "utility-types"
import { parse, stringify } from "qs"
import plural, { addRule as addPluralRule } from "plural"
import * as Validator from "yup"
import {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input"
import { parseDate } from "@cashbook/util-dates"

// warn when a context is used by not defined/set
export function undefinedContext(name = "Context") {
  console.warn(`Not in context of [${name}]`)
}

export function searchToQuery<
  T extends Record<
    string,
    string | number | Array<string> | Array<number>
  > = Record<string, never>
>(search = "?", options: qs.IParseOptions = {}): Optional<T> {
  return parse(search, {
    ignoreQueryPrefix: true,
    ...options,
  }) as Optional<T>
}

export function queryToSearch(
  query: Record<
    string,
    null | string | number | Array<string> | Array<number>
  > = {},
  options: qs.IStringifyOptions = {}
): string {
  return stringify(query, { addQueryPrefix: true, ...options })
}

function useEffectOnce(effect: EffectCallback) {
  useEffect(effect, [])
}

export function useMount(fn: () => void) {
  useEffectOnce(() => {
    fn()
  })
}

export function arePhoneNumbersSame(
  ...phoneNumbers: Array<string | null | undefined>
) {
  const numbers: Array<string> = phoneNumbers
    .filter((n) => n)
    .map((n) => (n || "").toString().replace(/\s/gi, ""))
  if (numbers.length !== phoneNumbers.length) return false
  return numbers.every((item) => item === numbers[0])
}

export function normalizeNumber(
  n: number,
  digitsAfterDecimal: number | undefined = 2
): number {
  let str = n.toString()
  if (digitsAfterDecimal !== undefined) {
    str = Number(str).toFixed(digitsAfterDecimal).toString()
  }
  if (parseInt(str) === parseFloat(str)) {
    str = parseInt(str).toString()
  }
  return Number(str)
}

export function getWhatsAppSharingLink({
  text,
  url,
  phoneNumber,
}: {
  phoneNumber?: string | number
  text?: string
  url?: string
}) {
  const domain = phoneNumber
    ? `https://wa.me/${phoneNumber.toString().replace(/[^\d]/gi, "")}`
    : "https://api.whatsapp.com/send"
  const message =
    text || url
      ? (text || "") +
        (url
          ? `
${url}
`
          : "")
      : ""
  return `${domain}?${[message ? "text=" + encodeURIComponent(message) : null]
    .filter(Boolean)
    .join("&")}`
}

export function useUnmount(fn: () => void) {
  const fnRef = useRef(fn)
  // update the ref each render so if it change the newest callback will be invoked
  fnRef.current = fn
  useEffect(() => () => fnRef.current(), [])
}

addPluralRule("is", "are")

const __PLURALIZED_CACHE__: { [key: string]: string } = {}
export function pluralize(word: string, count: number | Array<unknown> = 2) {
  if (Array.isArray(count)) count = count.length
  const key = `${word}__${count}`
  if (!__PLURALIZED_CACHE__[key]) {
    __PLURALIZED_CACHE__[key] = plural(word, count)
  }
  return __PLURALIZED_CACHE__[key]
}

export async function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export function useAsyncFetchWithStatus<Data = unknown>(
  fetcher: () => Promise<Data>
) {
  const [{ error, status, data }, setState] = useState<
    | {
        error: null
        data: null
        status: "init"
      }
    | {
        error: null
        data: Data
        status: "success"
      }
    | {
        data: null
        error: null
        status: "in_progress"
      }
    | {
        data: null
        error: Error
        status: "failed"
      }
  >({
    error: null,
    status: "init",
    data: null,
  })
  const fetchData = useCallback(async () => {
    setState({ status: "in_progress", error: null, data: null })
    try {
      const results = await fetcher()
      setState({ status: "success", error: null, data: results })
    } catch (e) {
      const error = e as Error
      setState({ status: "failed", data: null, error: error })
    }
  }, [fetcher])
  return {
    error,
    status,
    data,
    fetchData,
  }
}

export function saveBlobAs(blob: Blob, fileName: string) {
  const a = document.createElement("a")
  document.body.appendChild(a)
  a.style.display = "none"
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
  setTimeout(() => {
    a.remove()
  }, 5000)
}

/**
 * These are validators for input fields
 */
export const PhoneNumberValidator = Validator.string().test({
  name: `valid-phone-number`,
  test: (value: string | undefined | null) => {
    const currentLength = value ? String(value).trim().length : 0
    if (currentLength === 0 || !value) {
      // required attribute should handle the empty case
      return true
    }
    return isPossiblePhoneNumber(value)
  },
  message: function test() {
    return `Please enter a valid mobile number`
  },
})

export const EmailValidator = Validator.string()
  .email("Please provide a valid email address")
  .nullable()

export const OTPValidator = Validator.string().test({
  name: `valid-otp-code`,
  test: (value: string | undefined | null) => {
    const currentLength = value ? String(value).trim().length : 0
    if (currentLength === 0 || !value || value.length <= 6) {
      // required attribute should handle the empty case
      return true
    }
    return false
  },
  message: function test() {
    return `Please enter complete 6-digit OTP`
  },
})

export const GSTValidator = Validator.string().test({
  name: `valid-gst-number`,
  test: (value: string | undefined) => {
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    if (value.length) {
      if (!gstRegex.test(value)) {
        return false
      }
    }
    return true
  },
  message: function test() {
    return `Please enter a valid GST number.`
  },
})

// Validators ends here

export function b64toBlob(b64Data: string, contentType: string) {
  const byteCharacters = atob(b64Data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], { type: contentType })
  return blob
}

export function isPhoneNumberIndian(phoneNumber?: string) {
  if (phoneNumber && parsePhoneNumber(phoneNumber)?.country === "IN") {
    return true
  }
  return false
}

export function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const base64String = (reader.result as string).split(",")[1]
      resolve(base64String)
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(file)
  }) as Promise<string>
}

export function isVisitorIndian(): boolean {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (
    timeZone === "Asia/Kolkata" ||
    timeZone === "Asia/Calcutta" ||
    timeZone === "Asia/Delhi" ||
    timeZone === "Asia/Mumbai"
  )
    return true
  return false
}

export const RECORD_ENTRY_MIN_DATE = parseDate(
  "2000-01-01",
  "yyyy-MM-dd",
  new Date()
)

export enum FileMimeTypes {
  // Image Types
  IMAGE_JPEG = "image/jpeg",
  IMAGE_JPG = "image/jpg",
  IMAGE_PNG = "image/png",
  IMAGE_GIF = "image/gif",
  IMAGE_WEBP = "image/webp",
  IMAGE_BMP = "image/bmp",
  IMAGE_TIFF = "image/tiff",
  IMAGE_SVG = "image/svg+xml",
  IMAGE_AVIF = "image/avif",
  IMAGE_HEIF = "image/heif",
  IMAGE_HEIC = "image/heic",

  // Document Types
  APPLICATION_PDF = "application/pdf",
  APPLICATION_MSWORD = "application/msword", // .doc
  APPLICATION_WORDPROCESSINGML_DOCUMENT = "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  APPLICATION_MS_EXCEL = "application/vnd.ms-excel", // .xls
  APPLICATION_SPREADSHEETML_SHEET = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  APPLICATION_MS_POWERPOINT = "application/vnd.ms-powerpoint", // .ppt
  APPLICATION_PRESENTATIONML_PRESENTATION = "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
  APPLICATION_JSON = "application/json",
  TEXT_PLAIN = "text/plain",
  TEXT_CSV = "text/csv",
}

export const getMimeTypeFromBase64 = (
  base64String: string | null | undefined
): string | null => {
  if (
    !base64String ||
    typeof base64String !== "string" ||
    base64String.length < 4
  ) {
    return null
  }

  const prefix: string = base64String.substring(0, 25)

  if (prefix.startsWith("/9j/")) {
    return FileMimeTypes.IMAGE_JPEG
  }
  if (prefix.startsWith("iVBORw0KGgoAAAANSUhEUg")) {
    return FileMimeTypes.IMAGE_PNG
  }
  if (prefix.startsWith("JVBERi0")) {
    return FileMimeTypes.APPLICATION_PDF
  }

  const base64Chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"
  const charMap: { [key: string]: number } = {}

  for (let i = 0; i < base64Chars.length; i++) {
    charMap[base64Chars[i]] = i
  }

  try {
    const getPrefixBytes = (base64: string): number[] => {
      const padding: number = base64.indexOf("=")
      const length: number = padding > 0 ? padding : base64.length

      const prefixLength: number = Math.min(length, 12)
      const bytes: number[] = []

      for (let i = 0; i < prefixLength; i += 4) {
        const char1: string = base64[i]
        const char2: string = base64[i + 1] || "="
        const char3: string = base64[i + 2] || "="
        const char4: string = base64[i + 3] || "="

        if (char1 === "=" || char2 === "=" || char3 === "=" || char4 === "=") {
          continue
        }

        const value1: number = charMap[char1]
        const value2: number = charMap[char2]
        const value3: number = charMap[char3]
        const value4: number = charMap[char4]

        bytes.push(value1 * 4 + value2 / 16)
        if (char3 !== "=") {
          bytes.push((value2 % 16) * 16 + value3 / 4)
        }
        if (char4 !== "=") {
          bytes.push((value3 % 4) * 64 + value4)
        }
      }
      return bytes
    }
    const prefixBytes: number[] = getPrefixBytes(base64String)
    const prefixBytesAsHex: string = prefixBytes
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("")

    const mimeTypes: { [key: string]: string } = {
      ffd8ffe: FileMimeTypes.IMAGE_JPEG,
      "89504e47": FileMimeTypes.IMAGE_PNG,
      "47494638": FileMimeTypes.IMAGE_GIF,
      "25504446": FileMimeTypes.APPLICATION_PDF,
    }

    for (const [prefix, mimeType] of Object.entries(mimeTypes)) {
      if (prefixBytesAsHex.startsWith(prefix)) {
        return mimeType
      }
    }
  } catch (e) {
    return null
  }

  return null
}

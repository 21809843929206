import { useParams } from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import {
  AppDownloadIcon,
  Box,
  Button,
  ControlExpensesIcon,
  DataLoadingFallback,
  getButtonClassName,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  SpinnerIcon,
  Stack,
  Switch,
  Text,
  useOverlayTriggerState,
  WalletDeactivated,
} from "@cashbook/web-components"
import { usePauseWallet, useWallet } from "@cashbook/data-store/payments"
import { BreadCrumbsForPayments } from "../Payments"
import config from "../config"
import { toast } from "react-hot-toast"
import { useState } from "react"
import { getDeviceOS } from "@cashbook/device-info"

export default function WalletSettingsPage() {
  const { staffId, businessId } = useParams()
  if (!staffId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading wallet settings..." />}
        traceId="loading_wallets_settings"
      >
        <WalletSettings
          key={staffId}
          staffId={staffId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function WalletSettings({
  businessId,
  staffId,
}: {
  businessId: string
  staffId: string
}) {
  const os = getDeviceOS()
  const { user, authUser, userProfile, getTeamMemberInfoForId } = useWallet(
    businessId,
    staffId
  )
  const { pauseUnpauseWallet } = usePauseWallet()
  const [isWalletPaused, setIsWalletPaused] = useState(
    Boolean(getTeamMemberInfoForId(user.uid)?.wallet?.paused_at)
  )
  const [actionLoading, setActionLoading] = useState(false)

  const isLoggedInUser = authUser?.uid === user?.uid

  const onConfirm = (successCallback: () => void) => {
    setActionLoading(true)
    pauseUnpauseWallet(
      businessId,
      staffId,
      isWalletPaused ? "UNPAUSE" : "PAUSE"
    )
      .then(() => {
        setIsWalletPaused((prevVal) => !prevVal)
        let toastMessage = ""
        if (!isWalletPaused) {
          toastMessage = isLoggedInUser
            ? `Your wallet has been paused`
            : `${user?.name}’s wallet has been paused`
        } else {
          toastMessage = isLoggedInUser
            ? `Your wallet has been unpaused`
            : `${user?.name}’s wallet has been unpaused`
        }
        toast.success(toastMessage)
      })
      .catch(() => {
        let errorMessage = ""
        if (!isWalletPaused) {
          errorMessage = isLoggedInUser
            ? `Failed to pause your wallet`
            : `Failed to pause ${user?.name}’s wallet`
        } else {
          errorMessage = isLoggedInUser
            ? `Failed to unpause your wallet`
            : `Failed to unpause ${user?.name}’s wallet`
        }
        toast.error(errorMessage)
      })
      .finally(() => {
        successCallback()
        setActionLoading(false)
      })
  }

  const getModalDetails = () => {
    let title = "",
      description = ""
    if (!isWalletPaused) {
      title = isLoggedInUser
        ? "Pause Your Wallet?"
        : `Pause ${user?.name}’s Wallet?`
      description = isLoggedInUser
        ? "This will temporarily disable UPI transactions from your wallet until you unpause it."
        : `This will not let ${user?.name} do UPI transactions until you unpause the wallet.`
    } else {
      title = isLoggedInUser
        ? "Unpause Your Wallet?"
        : `Unpause ${user?.name}’s Wallet?`
      description = isLoggedInUser
        ? "This will re-enable UPI transactions from your wallet."
        : `This will allow ${user?.name} to resume UPI transactions from their wallet.`
    }
    return {
      modalTitle: title,
      modalDescription: description,
    }
  }
  const modalDetails = getModalDetails()

  return (
    <Box key={businessId} height="full">
      <BreadCrumbsForPayments
        position="relative"
        title="Wallet Limits & Settings"
        removeBorderLine
        links={[
          { to: "payments", title: "Payments" },
          { to: "staff-wallets", title: "Member Wallets" },
          {
            to: staffId,
            title: isLoggedInUser
              ? "Your Wallet"
              : userProfile?.name || user?.name || "Staff Wallet Details",
          },
          {
            to: "wallet-settings",
            title: "Wallet Limits & Settings",
          },
        ]}
      />
      <Box marginLeft="8" width="1/2">
        <Text fontSize="s4" color="textMedium">
          Wallet Settings
        </Text>
        <Inline
          borderRadius="sm"
          borderWidth="1"
          borderColor="borderOutline"
          padding="6"
          marginTop="4"
          alignItems="center"
        >
          <Box
            borderRadius="full"
            height="10"
            width="10"
            backgroundColor="surfacePrimaryLowest"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WalletDeactivated size="6" color="iconPrimary" />
          </Box>
          <Stack flex="1" marginX="6">
            <Text fontSize="s3">Pause Wallet</Text>
            <Box marginTop="2">
              <Text fontSize="b3" color="textMedium">
                Temporarily pause wallet transactions
              </Text>
            </Box>
          </Stack>
          <PauseUnpauseConfirmationModal
            title={modalDetails.modalTitle}
            description={modalDetails.modalDescription}
            onConfirm={onConfirm}
            loading={actionLoading}
          >
            {({ open }) => (
              <Switch
                id="Pause/Unpause Toggle"
                label="Pause/Unpause Toggle"
                on={isWalletPaused}
                onToggle={() => {
                  open()
                }}
              />
            )}
          </PauseUnpauseConfirmationModal>
        </Inline>
        <Stack marginTop="6">
          <Text fontSize="s4" color="textMedium">
            Daily Wallet Limits
          </Text>
          <Stack
            marginTop="4"
            borderRadius="sm"
            borderWidth="1"
            borderColor="borderOutline"
            padding="6"
          >
            <ControlExpensesIcon size="10" />
            <Box marginTop="4">
              <Text fontSize="s3">Use mobile app to use this feature</Text>
            </Box>
            <Box marginTop="2">
              <Text fontSize="b4" color="textMedium">
                This feature is not available on web app yet. Please use the
                mobile app.
              </Text>
            </Box>
            <Box marginTop="4">
              <a
                href={
                  os === "ios"
                    ? config.appStoreDownloadLink
                    : config.appDownloadLink
                }
                target="_blank"
                className={getButtonClassName({ size: "lg", type: "submit" })}
                rel="noreferrer"
              >
                <AppDownloadIcon /> Download mobile app
              </a>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

function PauseUnpauseConfirmationModal({
  onConfirm,
  children,
  title,
  description,
  loading,
  ...props
}: {
  onConfirm: (successCallback: () => void) => void
  children: (props: { open: () => void }) => React.ReactNode
  title: string
  description: string
  loading: boolean
}) {
  const confirmation = useOverlayTriggerState({})

  return (
    <>
      {children({
        open: confirmation.open,
      })}
      <Modal
        isDismissable
        isOpen={confirmation.isOpen}
        onClose={confirmation.close}
        title={title}
      >
        <ModalBody>
          <Text>Are you sure?</Text>
          <Inline marginTop="4" alignItems="center">
            <Box
              width="2"
              height="2"
              borderRadius="full"
              backgroundColor="iconLow"
            />
            <Box marginLeft="4">
              <Text fontSize="b3">{description}</Text>
            </Box>
          </Inline>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            size="lg"
            disabled={loading}
            onClick={() => {
              onConfirm(confirmation.close)
            }}
          >
            {loading ? (
              <>
                <SpinnerIcon /> Yes
              </>
            ) : (
              <>Yes</>
            )}
          </Button>
          <Button size="lg" disabled={loading} onClick={confirmation.close}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

import {
  Button,
  DocumentDownloadIcon,
  Modal,
  ModalBody,
  ModalFooter,
  SpinnerIcon,
  Stack,
  Text,
} from "@cashbook/web-components"
import { useOverlayTriggerState } from "@react-stately/overlays"
import { useState } from "react"
import { toast } from "react-hot-toast"
import {
  MasterWalletReportExportType,
  MasterWalletTransactionsFilters,
  MasterWalletTransactionsPayload,
  getMasterWalletTransactionsReport,
} from "@cashbook/data-store/payments"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"

function ExportMasterWalletTransactionsInModal({
  children,
  searchParams,
  businessId,
}: {
  children: ({
    onExport,
  }: {
    onExport: (type: MasterWalletReportExportType) => void
  }) => JSX.Element
  searchParams: MasterWalletTransactionsFilters
  businessId: string
}) {
  const state = useOverlayTriggerState({})
  const [status, setStatus] = useState<"idle" | "in_progress">("idle")
  const [exportType, setExportType] =
    useState<MasterWalletReportExportType>("pdf")

  async function downloadFile() {
    setStatus("in_progress")
    trackEvent(TrackingEvents.DOWNLOAD_MASTER_WALLET_STATEMENT_REPORT, {
      reportType: exportType as "pdf" | "csv",
    })
    try {
      const constructedPayload: Omit<
        MasterWalletTransactionsPayload,
        "skip" | "take"
      > & {
        reportType: MasterWalletReportExportType
      } = {
        businessId,
        reportType: exportType,
      }

      // Add filters if present
      if (searchParams.transaction_type !== "all") {
        constructedPayload.transaction_type =
          searchParams.transaction_type === "B2B"
            ? "VIRTUAL_ACCOUNT_CREDIT,C2B"
            : searchParams.transaction_type
      }
      if (searchParams.from_datetime) {
        constructedPayload.from_datetime =
          searchParams.from_datetime.toISOString()
      }
      if (searchParams.to_datetime) {
        constructedPayload.to_datetime = searchParams.to_datetime.toISOString()
      }

      const response = await getMasterWalletTransactionsReport<Blob>(
        constructedPayload
      )

      // Create download link
      const url = window.URL.createObjectURL(response)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute(
        "download",
        `master-wallet-transactions-${new Date().toISOString()}.${
          exportType === "csv" ? "xlsx" : exportType
        }`
      )
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      toast.success(`${exportType.toUpperCase()} downloaded successfully`)
      state.close()
    } catch (error) {
      toast.error("Failed to download report")
    } finally {
      setStatus("idle")
    }
  }

  function onExport(type: MasterWalletReportExportType) {
    setExportType(type)
    state.open()
  }

  return (
    <>
      {children({ onExport })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        isDismissable
        size="lg"
        title="Export Master Wallet Transactions"
      >
        <ModalBody>
          <Stack gap="4">
            <Text>
              Download {exportType === "pdf" ? "PDF" : "Excel"} report for
              master wallet transactions
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          {status === "in_progress" ? (
            <Button status="primary" disabled size="lg">
              <SpinnerIcon /> Please wait...
            </Button>
          ) : (
            <Button level="primary" autoFocus size="lg" onClick={downloadFile}>
              <DocumentDownloadIcon /> Download{" "}
              {exportType === "csv" ? "Excel" : exportType.toUpperCase()}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ExportMasterWalletTransactionsInModal

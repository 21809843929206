/* eslint-disable @typescript-eslint/no-explicit-any */
import hmacSHA256 from "crypto-js/hmac-sha256"
import { Auth, getAuth } from "firebase/auth"
import {
  setAnanlyticsIdentity,
  trackEvent,
  TrackingEvents,
} from "@cashbook/util-tracking"
import { clearFirestoreCache } from "@cashbook/data-store/utils"
import { logError, setLoggerIdentity } from "@cashbook/util-logging"
import {
  clearExternalStorage,
  removeSyncStoredItem,
} from "@cashbook/data-store/storage"
import { toast } from "react-hot-toast"
import { getAppCheckToken } from "@cashbook/app-check"

type HttpsHeaders = {
  Accept: string
  "Content-Type": string
  Authorization: string
  "X-REQUEST-SIGNATURE"?: string
  "X-Firebase-AppCheck"?: string
}

export enum BACKEND_STATUS_CODES {
  UNAUTHORIZED = 401,
  SERVICE_UNAVAILABLE = 503,
  M2P_ERROR = 500,
  UNPROCESSABLE_ENTITY = 422,
  BAD_REQUEST = 400,
}

export type Options = {
  timeout?: number
  headers?: Record<string, string | number | boolean>
  payload?: Record<string, any>
  abortController?: AbortController
  version?: "v1" | "v2" | "v3"
  isAuthenticatedRequest?: boolean
}

const defaultOptions: Options = {
  timeout: 20000,
  isAuthenticatedRequest: true,
}

export const config = {
  baseUrl: "" as string,
  paymentsHashKey: "" as string,
}

export function configure(values: typeof config) {
  config.baseUrl = values.baseUrl
  config.paymentsHashKey = values.paymentsHashKey
}

export const PAYMENTS_API_VERSION = "v1"

export async function callApiService({
  method,
  endpoint,
  options,
}: {
  endpoint: string
  method: "GET" | "POST"
  options: Options
}): Promise<unknown> {
  const optionsForApi = {
    ...defaultOptions,
    ...options,
  }

  const auth = getAuth()

  const BASE_URL = `${
    config.baseUrl + (optionsForApi.version || PAYMENTS_API_VERSION)
  }/`
  let idToken = ""
  let appCheckToken = ""
  try {
    try {
      const appCheckTokenResponse = await getAppCheckToken()
      appCheckToken = appCheckTokenResponse
    } catch (appCheckError) {
      logError(appCheckError as Error)
    }

    if (optionsForApi.isAuthenticatedRequest && auth.currentUser) {
      const idTokenResult = await auth.currentUser.getIdTokenResult()
      idToken = idTokenResult.token || ""
    }
  } catch (e) {
    const error = e as Error
    throw new Error(error.message)
  }
  if (optionsForApi.isAuthenticatedRequest && !idToken) {
    throw new Error("Please login before you use payments module.")
  }

  let requestTimedOut = false
  let url: string = BASE_URL + `${endpoint}`
  // code to append query parameters in the api url
  if (
    method === "GET" &&
    optionsForApi.payload &&
    Object.keys(optionsForApi.payload).length > 0
  ) {
    url += `?${getQueryParams(optionsForApi.payload)}`
  }
  const body = method === "GET" ? null : JSON.stringify(optionsForApi.payload) // setting body for post request
  const headers: HttpsHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: idToken ? `${"Bearer "}${idToken}` : "",
    ...optionsForApi.headers,
  }
  if (appCheckToken) {
    headers["X-Firebase-AppCheck"] = appCheckToken
  }
  if (body) {
    headers["X-REQUEST-SIGNATURE"] = hmacSHA256(
      body,
      config.paymentsHashKey
    ).toString()
  }

  return new Promise((resolve, reject) => {
    // Handling timeouts
    const controller = new AbortController()
    const timerId = setTimeout(() => {
      const controllers: AbortController[] = optionsForApi.abortController
        ? [controller].concat(optionsForApi.abortController)
        : [controller]
      requestTimedOut = true
      abortControllers(controllers)
    }, optionsForApi.timeout)

    const signal = options.abortController?.signal || controller.signal

    const promise = fetch(url, {
      method,
      headers,
      body,
      signal,
    })
    promise
      .then(async (response: Response) => {
        const statusCode = response.status
        if (statusCode === 200 || statusCode === 201) {
          const contentType = response.headers.get("Content-Type")
          const fileType = getFileTypeFromContentType(contentType)
          if (contentType?.includes("application/json")) {
            return await response.json()
          } else if (
            fileType === "application/pdf" ||
            fileType === "application/vnd.ms-excel" ||
            fileType ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            return await response.blob()
          } else {
            throw new Error(`Unsupported response format`)
          }
        } else {
          let error: Error & { statusCode: number }
          try {
            // response returned is valid json
            error = await response.json()
          } catch (e) {
            const err = e as Error
            // response returned is invalid json
            if (statusCode === BACKEND_STATUS_CODES.SERVICE_UNAVAILABLE) {
              error = {
                statusCode: statusCode,
                stack: `${statusCode}`,
                message: "Service Temporarily Unavailable",
                name: `${statusCode}`,
              }
            } else {
              error = {
                statusCode: statusCode,
                stack: `${statusCode}`,
                message: err.message,
                name: `InvalidJsonResponse`,
              }
            }
          }
          if (
            error.statusCode === BACKEND_STATUS_CODES.UNAUTHORIZED &&
            optionsForApi.isAuthenticatedRequest
          ) {
            try {
              logout(auth)
            } catch (e) {
              const err = e as Error
              toast.error(err.message || "Something went wrong")
              logError(err)
            }
          }
          reject(handlePaymentsApiErrors(error))
        }
      })
      .then((resolved: Response) => {
        clearInterval(timerId)
        resolve(resolved)
      })
      .catch((rejected) => {
        clearInterval(timerId)
        if (signal.aborted && !requestTimedOut) {
          return
        }
        reject(rejected)
      })
  })
}

async function logout(auth: Auth) {
  await auth.signOut()
  clearFirestoreCache()
  clearExternalStorage()
  setAnanlyticsIdentity(null)
  setLoggerIdentity(null)
  trackEvent(TrackingEvents.LOGOUT, {
    type: "fromThisDevice",
  })
  const keysToBeRemovedOnLogout = [
    "pdf_options",
    "pdf_other_options",
    "ajs_user_traits",
    "dismissedBanners",
    "isNewUser",
    "bookRolesAndPermissions",
    "staffInfoTransitioned",
  ]
  keysToBeRemovedOnLogout.forEach((key) => {
    removeSyncStoredItem(key)
  })
}

function getQueryParams(obj: Record<string, any>, prefix = ""): string {
  const queryParams: string = Object.entries(obj)
    .map(([key, value]) => {
      const fullKey = prefix ? `${prefix}[${key}]` : key

      if (typeof value === "object" && value !== null) {
        return getQueryParams(value, fullKey) // Recursive call
      } else {
        return `${encodeURIComponent(fullKey)}=${encodeURIComponent(
          String(value)
        )}`
      }
    })
    .join("&")

  return queryParams
}

const abortControllers = (controllers: AbortController[]) => {
  controllers.forEach((controller) => {
    controller.abort()
  })
}

const errorMessageText = {
  internetProblem: "Please check your internet connection.",
  somethingWentWrong: "Something went wrong, please try again",
  timeoutError:
    "There was a problem with your request. Please try again later.",
  requestCancelled: "Request cancelled",
  serviceUnavailable:
    "Our services are temporarily unavailable due to maintenance. Please try again later.",
}

type ErrorWithStatusCode = Error & {
  statusCode: number
}

export enum FRONTEND_API_ERRORS_MESSAGES {
  TimeoutError = "Aborted",
  NoInternet = "Network request failed",
  FetchingDeviceInfoError = "Mobile Number not verified!",
}

export const handlePaymentsApiErrors = (error: ErrorWithStatusCode) => {
  let message = ""
  if (
    error?.statusCode === BACKEND_STATUS_CODES.SERVICE_UNAVAILABLE ||
    error?.statusCode === BACKEND_STATUS_CODES.M2P_ERROR
  ) {
    message = errorMessageText.serviceUnavailable
  } else if (error?.message === FRONTEND_API_ERRORS_MESSAGES.NoInternet) {
    message = errorMessageText.internetProblem
  } else if (error?.message === FRONTEND_API_ERRORS_MESSAGES.TimeoutError) {
    message = errorMessageText.timeoutError
  } else {
    message = error.message
  }
  return { ...error, message }
}

type SupportedFileTypes =
  | "application/pdf"
  | "application/vnd.ms-excel"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
function getFileTypeFromContentType(
  contentType: string | null
): SupportedFileTypes | null {
  if (contentType?.includes("application/pdf")) return "application/pdf"
  else if (
    contentType?.includes(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
  )
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  else if (contentType?.includes("application/vnd.ms-excel"))
    return "application/vnd.ms-excel"
  return null
}

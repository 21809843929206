import {
  formatDate,
  getHours,
  getMinutes,
  getSeconds,
  setHours,
  setMinutes,
  setSeconds,
} from "@cashbook/util-dates"
import { normalizeNumber } from "@cashbook/util-general"
import classNames from "classnames"
import evalNumExpr from "eval-num-expr"
import {
  connect,
  Field,
  FieldProps,
  FormikConfig,
  FormikErrors,
  getIn,
  useField,
} from "formik"
import React, { useMemo, useState } from "react"
import { DayPicker, Matcher } from "react-day-picker"
import "react-day-picker/dist/style.css"
import { $PropertyType } from "utility-types"
import { Button, ButtonLink, getButtonClassName } from "./Button"
import {
  ArrowLeftIcon,
  CalendarIcon,
  CameraIcon,
  CancelIcon,
  DocumentDownloadIcon,
  InformationCircleIcon,
  PencilIcon,
  TrashIcon,
} from "./Icons"
import { Modal, ModalBody, ModalFooter, useOverlayTriggerState } from "./Modal"
import { readFileAsDataURL } from "./util"
import "./form.css"
import { Menu, MenuButton, MenuList } from "./Menu"
import { Text } from "./Text"
import { Inline } from "./Inline"
import { Box } from "./Box"
import { toast } from "react-hot-toast"

export const Input = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(function Input({ className, ...props }, ref) {
  return (
    <input
      ref={ref}
      className={classNames(
        "block rounded bg:gray-100 placeholder-gray-500 border border-gray-100",
        className,
        props.type === "checkbox"
          ? "form-checkbox"
          : props.type === "radio"
          ? "form-radio"
          : "form-input"
      )}
      {...props}
    />
  )
})

export const Select = React.forwardRef<
  HTMLSelectElement,
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
>(function Select({ className, ...props }, ref) {
  return (
    <select
      ref={ref}
      className={classNames(
        "block rounded bg:gray-100 placeholder-gray-500 border border-gray-100",
        className,
        props.multiple ? "form-multiselect" : "form-select"
      )}
      {...props}
    />
  )
})

export const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >
>(function Select({ className, ...props }, ref) {
  return (
    <textarea
      ref={ref}
      className={classNames(
        "block rounded bg:gray-100 placeholder-gray-500 border border-gray-100",
        className,
        "form-textarea"
      )}
      {...props}
    />
  )
})

type FormFieldProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "name"
> & {
  label?: React.ReactNode
  name: string
  secondaryLabel?: string
  actionLabel?: React.ReactNode
  renderInput?: (props: FieldProps) => React.ReactNode
  help?: React.ReactNode
  noMargin?: boolean
  invisibleInput?: boolean
  hideError?: boolean
  hideAsterisk?: boolean
}

export function FormField({
  label,
  name,
  id,
  ref,
  renderInput,
  help,
  noMargin = false,
  invisibleInput = false,
  hideError = false,
  secondaryLabel,
  actionLabel,
  hideAsterisk,
  ...props
}: FormFieldProps) {
  const fieldId = id || name
  const labelAlignment =
    props.type === "checkbox" || props.type === "radio" ? "After" : "Before"
  return (
    <Field name={name} type={props.type} value={props.value}>
      {(fieldProps: FieldProps) => {
        const { field, meta } = fieldProps
        return (
          <div
            className={classNames(
              "relative",
              { "mb-6": !noMargin },
              invisibleInput ? props.className : ""
            )}
          >
            <div
              className={classNames(
                "flex",
                props.type === "radio" || props.type === "checkbox"
                  ? `flex-row items-center${
                      !invisibleInput ? " space-x-2" : ""
                    }`
                  : "flex-col space-y-1"
              )}
            >
              {label && labelAlignment === "Before" ? (
                <InputLabel
                  fieldId={fieldId}
                  secondaryLabel={secondaryLabel}
                  actionLabel={actionLabel}
                >
                  <Text>
                    {label}
                    {!hideAsterisk && props.required ? (
                      <Text
                        as="span"
                        fontSize="lg"
                        color="iconError"
                        className="ml-1"
                      >
                        *
                      </Text>
                    ) : null}
                  </Text>
                </InputLabel>
              ) : null}

              {renderInput ? (
                renderInput(fieldProps)
              ) : (
                <Input
                  id={fieldId}
                  ref={ref as unknown as React.Ref<HTMLInputElement>}
                  {...field}
                  {...props}
                  className={classNames(props.className, {
                    "opacity-0 top-0 left-0 w-1 h-1 absolute": invisibleInput,
                  })}
                />
              )}
              {label && labelAlignment === "After" ? (
                <InputLabel
                  fieldId={fieldId}
                  secondaryLabel={secondaryLabel}
                  actionLabel={actionLabel}
                >
                  {label}
                </InputLabel>
              ) : null}
            </div>
            {help ? (
              <div className="text-gray-500 text-xs mt-1">{help}</div>
            ) : null}
            {!hideError && meta.error && meta.touched ? (
              <ErrorMessage name={name} />
            ) : null}
          </div>
        )
      }}
    </Field>
  )
}

export const ErrorMessage = connect<{ name: string }>(function ErrorMessage({
  formik,
  name,
}) {
  const errors: Record<string, unknown> = formik.errors || {}
  const allTouched = formik.touched
  const error = errors[name as unknown as string] || getIn(errors, name)
  const touched: boolean = getIn(allTouched, name)
  if (!error || !touched) return null
  return (
    <div className="px-2 py-1 mt-1 bg-red-100 border rounded border-red-100 text-red-900 text-sm">
      {Array.isArray(error) ? error.join(", ") : error}
    </div>
  )
})

export function InputLabel({
  children,
  fieldId,
  className,
  secondaryLabel,
  actionLabel,
  ...props
}: React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
> & {
  fieldId: string
  secondaryLabel?: string
  actionLabel?: React.ReactNode
}) {
  return (
    <div
      className={classNames("text-sm font-medium flex-1", {
        flex: actionLabel,
      })}
    >
      <label
        htmlFor={fieldId}
        className={classNames("cursor-pointer flex-1", className)}
        {...props}
      >
        {children}
        {secondaryLabel ? (
          <span className="text-gray-500 ml-3">({secondaryLabel})</span>
        ) : null}
      </label>
      {actionLabel ? <div>{actionLabel}</div> : null}
    </div>
  )
}

export function FormImageFileField({
  ref,
  label,
  noMargin,
  inputField,
  defaultValue,
  previewTitle,
  allowUpdate = true,
  thumbBorderColor,
  thumbBorderWidth,
  allowDownload,
  onRemove,
  onSelectionEnd,
  onSelectionStart,
  onPreview,
  ...props
}: FormFieldProps & {
  previewTitle?: string
  allowUpdate?: boolean
  allowDownload?: boolean
  inputField?: React.ReactNode
  thumbBorderWidth?: React.ComponentProps<typeof Box>["borderWidth"]
  thumbBorderColor?: React.ComponentProps<typeof Box>["borderColor"]
  onRemove?: () => void
  // Callback to handle the first image selection
  onSelectionStart?: () => void
  onSelectionEnd?: (file: File | undefined) => void
  onPreview?: () => void
}) {
  const [fileName, setFileName] = useState<string>("")
  // we need to keep this state because we want to show outlines on the labels
  // :focus-within can not be used because the input is outside of the the label
  // and creating duplicate inputs will not work as well due the how file inputs behave
  const [fileInputFocused, setFileIputFocusState] = useState<boolean>(false)
  const [dataUrl, setFileDataUrl] = useState<string | undefined>(
    defaultValue ? String(defaultValue) : undefined
  )
  const imagePreviewState = useOverlayTriggerState({ defaultOpen: false })
  const id = props.id || props.name
  return (
    <FormField
      {...props}
      noMargin={noMargin}
      renderInput={({ field, form }) => {
        return (
          <div className="relative">
            <input
              accept="images/*"
              {...props}
              name={`file_name_${id}`}
              type="file"
              id={id}
              value={fileName}
              className="opacity-0 absolute top-0 left-0 w-1 h-2"
              onFocus={() => {
                setFileIputFocusState(true)
              }}
              onBlur={() => {
                setFileIputFocusState(false)
                form.setFieldTouched(field.name, true)
              }}
              onChange={({ currentTarget: { value, files } }) => {
                form.setFieldTouched(field.name, true)
                form.setFieldValue(
                  field.name,
                  props.multiple ? files || [] : files ? files[0] : ""
                )
                setFileName(value)
                if (files && files.length) {
                  onSelectionEnd?.(files[0])
                  readFileAsDataURL(files[0]).then((dataUrl) => {
                    setFileDataUrl(dataUrl as string)
                  })
                } else {
                  setFileDataUrl(undefined)
                }
              }}
            />
            {!dataUrl ? (
              <InputLabel
                fieldId={id}
                onClick={() => {
                  onSelectionStart?.()
                }}
                className={classNames(getButtonClassName({}), {
                  "block ring-2 ring-blue-500": fileInputFocused,
                })}
              >
                {inputField ? (
                  inputField
                ) : (
                  <>
                    <CameraIcon />
                    <span>{label || "Select Image"}</span>
                  </>
                )}
              </InputLabel>
            ) : (
              <div className="flex space-x-4 items-center">
                <div className="border p-1px rounded overflow-hidden">
                  <Box
                    as="img"
                    src={dataUrl}
                    alt="Preview"
                    size="12"
                    cursor="pointer"
                    borderWidth={thumbBorderWidth || "1"}
                    rounded="md"
                    borderColor={thumbBorderColor || "iconMedium"}
                    onClick={() => {
                      imagePreviewState.open()
                      onPreview?.()
                    }}
                  />
                  <Modal
                    title={previewTitle || "Bill"}
                    isOpen={imagePreviewState.isOpen}
                    onClose={imagePreviewState.close}
                  >
                    <ModalBody>
                      <img
                        src={dataUrl}
                        alt="Preview"
                        className="w-full h-full max-w-full"
                      />
                    </ModalBody>
                    <ModalFooter>
                      {allowDownload ? (
                        <ButtonLink
                          size="lg"
                          href={dataUrl}
                          download="file.png"
                          target="_blank"
                          rel="noopner noreferrer"
                          level="primary"
                        >
                          <DocumentDownloadIcon /> Download Attachment
                        </ButtonLink>
                      ) : (
                        <Button
                          onClick={imagePreviewState.close}
                          level="primary"
                          autoFocus
                          size="lg"
                        >
                          Ok
                        </Button>
                      )}
                      {allowUpdate ? (
                        <InputLabel
                          fieldId={id}
                          className={getButtonClassName({ size: "lg" })}
                        >
                          <PencilIcon /> Change
                        </InputLabel>
                      ) : null}
                    </ModalFooter>
                  </Modal>
                </div>
                {allowUpdate ? (
                  <label
                    htmlFor={id}
                    className={classNames(
                      getButtonClassName({ inline: true }),
                      {
                        "block ring-2 ring-blue-900": fileInputFocused,
                      }
                    )}
                  >
                    <PencilIcon /> Change
                  </label>
                ) : null}
                <div
                  className={`flex items-center ${
                    allowUpdate && "border-l pl-4"
                  }`}
                >
                  <Button
                    inline
                    status="error"
                    onClick={() => {
                      form.setFieldValue(field.name, "")
                      setFileName("")
                      setFileDataUrl(undefined)
                      onRemove?.()
                    }}
                  >
                    <TrashIcon /> Delete
                  </Button>
                </div>
              </div>
            )}
          </div>
        )
      }}
    />
  )
}

export function DatePicker({
  onChange,
  value,
  inputProps,
  min,
  max,
  placeholder,
  onOpen,
  disabled = false,
}: {
  onChange: (date: Date | undefined) => void
  inputProps?: React.HTMLProps<HTMLInputElement> & { error?: string }
  value?: Date
  min?: Date
  max?: Date
  placeholder?: string
  onOpen?: () => void
  disabled?: boolean
}) {
  const disabledDays: Matcher = useMemo(() => {
    let disableDaysObj = {}
    if (min || max) {
      if (min) {
        disableDaysObj = { ...disableDaysObj, before: new Date(min) }
      }
      if (max) {
        disableDaysObj = {
          ...disableDaysObj,
          after: new Date(max),
        }
      }
    }
    return disableDaysObj as Matcher
  }, [min, max])
  return (
    <Menu>
      {({ isExpended, toggle }) => (
        <>
          <MenuButton
            inline
            onClick={() => {
              if (!isExpended) {
                onOpen?.()
              }
            }}
            disabled={disabled}
          >
            <DateInput
              value={`${
                value ? formatDate(value as Date, "dd MMM, yyyy") : ""
              }`}
              readOnly
              error={inputProps?.error}
              placeholder={placeholder || "DD MMM, YYYY"}
              style={{
                color: disabled ? "#757575" : "black",
                backgroundColor: disabled ? "#F5F5F5" : undefined,
              }}
              className={classNames(inputProps?.className, "w-full")}
              disabled={disabled}
            />
          </MenuButton>
          <MenuList>
            <DayPicker
              selected={new Date(value as Date)}
              mode={"single"}
              modifiersStyles={{
                selected: { color: "white", background: "#4863D4" },
                today: {
                  color:
                    new Date().toDateString() ===
                    new Date(value as Date).toDateString()
                      ? "white"
                      : "red",
                },
              }}
              disabled={disabledDays || disabled}
              onDayClick={(date: Date) => {
                if (value) {
                  // update the time (h,m,s) from the value
                  // because the the library removes and sets it to 12:00:00
                  date = (
                    [
                      [setHours, getHours],
                      [setMinutes, getMinutes],
                      [setSeconds, getSeconds],
                    ] as Array<[typeof setHours, typeof getHours]>
                  ).reduce<Date>(
                    (date, [setFn, getFn]) => setFn(date, getFn(value)),
                    date
                  )
                }
                onChange(new Date(date))
                toggle()
              }}
            />
          </MenuList>
        </>
      )}
    </Menu>
  )
}

type DateExtraInputs = React.ComponentProps<typeof Input> & {
  error?: string
  disabled?: boolean
}

const DateInput = React.forwardRef<HTMLInputElement, DateExtraInputs>(
  function DateInputInner(props, ref) {
    return (
      <div>
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pointer-events-none pl-3 ">
            <CalendarIcon color={props.disabled ? "iconLow" : "blue900"} />
          </div>
          <Input
            {...props}
            ref={ref}
            color="black"
            className={`bg-gray-50 focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${props.className}`}
          />
        </div>
        {props.error ? (
          <Box
            paddingY="1"
            paddingX="2"
            marginY="1"
            textAlign="left"
            rounded="md"
            backgroundColor="surfaceErrorLowest"
          >
            <Text fontSize="c2" color="textError">
              {props.error}
            </Text>
          </Box>
        ) : null}
      </div>
    )
  }
)

export function FormDatePicker({
  name,
  min,
  max,
  onOpen,
  disabled = false,
  ...props
}: Omit<
  Omit<
    React.ComponentProps<typeof FormField>,
    "value" | "onChange" | "renderInput"
  >,
  keyof React.ComponentProps<typeof DatePicker>
> &
  Omit<React.ComponentProps<typeof DatePicker>, "value" | "onChange"> & {
    name: string
  }) {
  return (
    <FormField
      name={name}
      {...props}
      renderInput={({ field, form }) => {
        return (
          <DatePicker
            value={field.value}
            min={min}
            max={max}
            onChange={(value) => form.setFieldValue(name, value)}
            onOpen={onOpen}
            inputProps={{
              onBlur: field.onBlur,
              name: field.name,
              id: props.id || field.name,
              disabled,
            }}
            disabled={disabled}
          />
        )
      }}
    />
  )
}

export function FormTimePicker({
  name,
  min,
  max,
  ...props
}: Omit<
  Omit<
    React.ComponentProps<typeof FormField>,
    "value" | "onChange" | "renderInput"
  >,
  keyof React.ComponentProps<typeof DatePicker>
> &
  Omit<React.ComponentProps<typeof DatePicker>, "value" | "onChange"> & {
    name: string
  }) {
  return (
    <FormField
      name={name}
      {...props}
      renderInput={({ field, form }) => {
        return (
          <>
            <div className="flex space-x-2">
              <Select
                id={props.id}
                placeholder="hh"
                value={field.value ? formatDate(field.value, "h") : ""}
                onBlur={() => {
                  form.setFieldTouched(field.name, true)
                }}
                onChange={(e) => {
                  const value = e.currentTarget.value || "0"
                  let hours = parseInt(value) % 12
                  const date = field.value || new Date()
                  const isPM = formatDate(date, "a") === "PM"
                  if (isPM) {
                    hours += 12
                  }
                  form.setFieldValue(name, setHours(date, hours))
                }}
                aria-label="Hours"
                disabled={props.disabled}
              >
                {Array.from(Array(12).keys()).map((val) => (
                  <option value={`${val + 1}`} key={val}>
                    {val + 1}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="mm"
                aria-label="Minutes"
                value={field.value ? getMinutes(field.value) : ""}
                onBlur={() => {
                  form.setFieldTouched(field.name, true)
                }}
                onChange={(e) => {
                  const value = e.currentTarget.value || "0"
                  const minutes = parseInt(value) % 60
                  const date = field.value || new Date()
                  form.setFieldValue(name, setMinutes(date, minutes))
                }}
                disabled={props.disabled}
              >
                {Array.from(Array(60).keys()).map((val) => (
                  <option value={`${val}`} key={val}>
                    {val}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="AM/PM"
                aria-label="AM/PM"
                value={field.value ? formatDate(field.value, "a") : ""}
                onBlur={() => {
                  form.setFieldTouched(field.name, true)
                }}
                onChange={(e) => {
                  const currentValue = formatDate(field.value, "a")
                  const date = field.value || new Date()
                  const hours = getHours(date)
                  switch (e.currentTarget.value) {
                    case "AM":
                      if (currentValue === "PM") {
                        // going from PM to AM, subtract 12 hours
                        form.setFieldValue(
                          name,
                          setHours(date, (hours - 12) % 24)
                        )
                      }
                      break
                    case "PM":
                      if (currentValue === "AM") {
                        // going from AM to PM, add 12 hours
                        form.setFieldValue(
                          name,
                          setHours(date, (hours + 12) % 24)
                        )
                      }
                      break
                  }
                }}
                disabled={props.disabled}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </Select>
            </div>
            <input
              type="text"
              name={name}
              value={field.value ? formatDate(field.value, "HH:mm:ss") : ""}
              readOnly
              hidden
              disabled={props.disabled}
            />
          </>
        )
      }}
    />
  )
}

export function FormAmountField({
  label,
  name,
  rawName,
  id,
  ref,
  renderInput,
  help,
  secondaryLabel,
  ...props
}: FormFieldProps & {
  rawName: string
}) {
  const fieldId = id || name
  const [rawField] = useField(rawName)
  const detailVisiblityState = useOverlayTriggerState({ defaultOpen: false })
  return (
    <Field name={name} type={props.type} value={props.value}>
      {(fieldProps: FieldProps) => {
        const { field, meta, form } = fieldProps
        const updateAmount = (textAmount: string) => {
          if (!textAmount.length) {
            form.setFieldValue(rawName, "")
            form.setFieldValue(field.name, "")
          }
          form.setFieldValue(
            rawName,
            textAmount.replace(/[^\d+-/x\\*\\%\s]/gi, "")
          )
          try {
            const amount = normalizeNumber(evalNumExpr(textAmount))
            form.setFieldValue(field.name, amount)
          } catch {
            if (meta.touched) {
              form.setFieldError(field.name, "Please enter valid amount")
              form.setFieldValue(field.name, 0)
            }
          }
        }
        return (
          <div className="mb-6">
            <div
              className={classNames(
                "flex",
                props.type === "radio" || props.type === "checkbox"
                  ? "flex-row items-center space-x-2"
                  : "flex-col space-y-1"
              )}
            >
              {label ? (
                <div className="flex items-center justify-between">
                  <Inline gap="1" position="relative">
                    <InputLabel
                      fieldId={fieldId}
                      secondaryLabel={secondaryLabel}
                    >
                      {label}
                    </InputLabel>
                    <Text
                      fontSize="lg"
                      color="iconError"
                      className="absolute -top-1 -right-3"
                    >
                      {props.required && " * "}
                    </Text>
                  </Inline>
                  {!props.disabled ? (
                    <button
                      className="text-gray-500"
                      type="button"
                      tabIndex={-1}
                      onClick={() =>
                        detailVisiblityState.isOpen
                          ? detailVisiblityState.close()
                          : detailVisiblityState.open()
                      }
                    >
                      <InformationCircleIcon />
                    </button>
                  ) : null}
                </div>
              ) : null}
              {renderInput ? (
                renderInput({
                  ...fieldProps,
                  field: {
                    ...field,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateAmount(e.currentTarget.value),
                    value: rawField.value,
                  },
                  meta,
                })
              ) : (
                <Input
                  id={fieldId}
                  ref={ref as unknown as React.Ref<HTMLInputElement>}
                  {...field}
                  {...props}
                  type="text"
                  inputMode="decimal"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateAmount(e.currentTarget.value)
                  }
                  value={rawField.value}
                  className={
                    props.disabled ? "text-[#757575] bg-[#F5F5F5]" : ""
                  }
                />
              )}
            </div>
            {help ? (
              <div className="text-gray-500 text-xs mt-1">{help}</div>
            ) : null}
            {field.value &&
            rawField.value &&
            normalizeNumber(field.value) !==
              normalizeNumber(Number(rawField.value)) ? (
              <div className="mt-2 font-medium text-lg">{field.value}</div>
            ) : null}
            {meta.error && meta.touched ? (
              <div className="px-2 py-1 mt-1 bg-red-100 border rounded border-red-100 text-red-900 text-sm">
                {meta.error}
              </div>
            ) : null}
            {detailVisiblityState.isOpen ? (
              <div className="p-4 border rounded-lg bg-blue-100 mt-3 relative">
                <div className="float-right">
                  <Button onClick={detailVisiblityState.close} inline>
                    <CancelIcon />
                  </Button>
                </div>
                <div className="font-medium mb-4 text-lg">
                  Amount Calculator
                </div>
                <p className="mb-4 text-gray-500">
                  You can use basic operations (e.g. +, -, *, /, % etc.) while
                  entering the amount to quickly calculate the total.
                </p>
                <div className="font-medium mb-2">Examples</div>
                <ul className="list-disc ml-5 mb-4">
                  {["175 + 40 + 2 * 32 - 100", "2 x 22 + 40 + 18%"].map(
                    (rawAmount) => (
                      <li key={rawAmount} className="mb-2">
                        <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
                          <p>
                            {rawAmount} ={" "}
                            {normalizeNumber(evalNumExpr(rawAmount))}
                          </p>
                          <div>
                            <Button
                              onClick={() => updateAmount(rawAmount)}
                              type="button"
                              inline
                            >
                              Try it <ArrowLeftIcon className="rotate-180 " />
                            </Button>
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
                <p className="mb-2 text-sm">
                  NOTE: "%" can be use to add/remove taxes
                </p>
              </div>
            ) : null}
          </div>
        )
      }}
    </Field>
  )
}

type TOnSubmit<T> = $PropertyType<FormikConfig<T>, "onSubmit">

// wrap formik onSubmit to automatically handle errors
export function formikOnSubmitWithErrorHandling<T>(
  handleSubmit: TOnSubmit<T>,
  rethrow?: boolean
): TOnSubmit<T> {
  return async (values, actions) => {
    if (!navigator.onLine)
      return toast.error("No internet available. Please try again later!")
    actions.setStatus()
    try {
      return await Promise.resolve(handleSubmit(values, actions))
    } catch (e) {
      if (!navigator.onLine) {
        return actions.setStatus(
          "No internet available. Please try again later!"
        )
      }
      if (!e) return
      const error = e as string | (Error & { formikErrors?: FormikErrors<T> })
      if (typeof error === "string") {
        actions.setStatus(error)
      } else if (error.message) {
        actions.setStatus(error.message)
        if (error.formikErrors) {
          actions.setErrors(error.formikErrors)
        }
      }
      if (rethrow) {
        return Promise.reject(e)
      }
    }
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useReducer, useState } from "react"
import {
  addNewCategory,
  assignCategory,
  BusinessTransactionsPayload,
  deleteCategory,
  enableDisableSingleCategory,
  getAllParties,
  getBusinessTransactions,
  updateCategory,
} from "./services"
import { PartyForFilters, PaymentsTransaction } from "./types"
import { useFormik } from "formik"
import { usePagination } from "./utils"
import { toast } from "react-hot-toast"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { useRemoteConfigBoolean } from "reactfire"
import { categoryListStore } from "@cashbook/data-store/storage"

type BusinessPartiesResponse = {
  data: PartyForFilters[]
}

export function useFetchParties(businessId: string) {
  const getParties = useCallback(async () => {
    const response = await getAllParties<BusinessPartiesResponse>(businessId)
    return response
  }, [businessId])
  return {
    fetchParties: getParties,
  }
}

type TransactionDetails = {
  totalExpenses?: number
  totalCollections?: number
  fetching?: boolean
  fetchingMore?: boolean
  totalTransactions?: number
  transactions?: PaymentsTransaction[]
}
type OverallBusinessExpensesState = {
  status: "in_progress" | "success" | "failed"
  error?: string | Error | null
  transactionDetails?: TransactionDetails
}
type TYPE_AND_PAYLOAD =
  | { type: "FETCHING_TRANSACTIONS" }
  | { type: "FETCHED_TRANSACTIONS"; payload: TransactionDetails }
  | { type: "FETCHING_TRANSACTIONS_FAILED"; payload: Error }
  | { type: "FETCHING_TRANSACTIONS_WITH_FILTERS_APPLIED" }
  | { type: "FETCHING_MORE_TRANSACTIONS" }
  | {
      type: "FETCHED_MORE_TRANSACTIONS"
      payload: PaymentsTransaction[]
    }

const initialDashboardState: OverallBusinessExpensesState = {
  status: "in_progress",
  error: null,
  transactionDetails: undefined,
}

const reducer = (
  state: OverallBusinessExpensesState,
  action: TYPE_AND_PAYLOAD
): OverallBusinessExpensesState => {
  switch (action.type) {
    case "FETCHING_TRANSACTIONS":
      return {
        ...state,
        status: "in_progress",
        error: null,
        transactionDetails: undefined,
      }
    case "FETCHED_TRANSACTIONS":
      return {
        ...state,
        status: "success",
        error: null,
        transactionDetails: {
          fetching: false,
          fetchingMore: false,
          transactions: action.payload.transactions,
          totalExpenses: action.payload.totalExpenses,
          totalCollections: action.payload.totalCollections,
          totalTransactions: action.payload.totalTransactions,
        },
      }
    case "FETCHING_TRANSACTIONS_FAILED":
      return {
        ...state,
        status: "failed",
        error: action.payload,
        transactionDetails: undefined,
      }
    case "FETCHING_TRANSACTIONS_WITH_FILTERS_APPLIED":
      return {
        ...state,
        transactionDetails: {
          fetching: true,
          fetchingMore: false,
          transactions: undefined,
          totalExpenses: undefined,
          totalCollections: undefined,
          totalTransactions: undefined,
        },
      }
    case "FETCHING_MORE_TRANSACTIONS":
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          fetchingMore: true,
        },
      }
    case "FETCHED_MORE_TRANSACTIONS":
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          fetchingMore: false,
          transactions: state.transactionDetails?.transactions?.length
            ? [...state.transactionDetails.transactions, ...action.payload]
            : action.payload,
        },
      }
    default:
      return state
  }
}

type BusinessTransactionsFilters = {
  dateFilterLabel?: string
  from_datetime?: Date
  to_datetime?: Date
  skip?: number
  take?: number
  status?: "SUCCESS" | "PENDING" | "REFUNDED"
  member?: { id: string; name: string; businessUserId?: string }
  party?: { id: string; name: string; ids: string[] }
  attachments?: { id: "attached" | "missing"; label: string }
  categories?: { id: string; label: string }
}
const initialTransactionFilters: BusinessTransactionsFilters = {}
export function useOverallBusinessTransactions(
  businessId: string,
  initialTransactionParams: BusinessTransactionsFilters = initialTransactionFilters
) {
  const [state, dispatch] = useReducer(reducer, initialDashboardState)
  const { data: paymentCollectionsEnabled } = useRemoteConfigBoolean(
    "payment_collections_enabled"
  )

  const initialSearchParams = useMemo(() => {
    if (!initialTransactionParams) return initialTransactionFilters
    return {
      ...initialTransactionFilters,
      ...initialTransactionParams,
    }
  }, [initialTransactionParams])

  const {
    values: params,
    setValues,
    setFieldValue,
  } = useFormik<BusinessTransactionsFilters>({
    initialValues: initialSearchParams,
    onSubmit: () => undefined,
  })

  const {
    current,
    pagination,
    lastPage,
    next,
    reset: resetPagination,
  } = usePagination({
    skip: 0,
    take: 25,
    totalItems: state.transactionDetails?.totalTransactions || 0,
  })

  const getTransactions = useCallback(async () => {
    try {
      const constructedPayload: BusinessTransactionsPayload = {
        businessId,
        skip: pagination.skip,
        take: pagination.take,
      }
      const payloadForEvent: {
        from: "businessTransactions"
        dateFilter?: string
        membersFilter?: boolean
        partiesFilter?: boolean
        statusFilter?: string
        billProofFilter?: "attached" | "missing"
        categoriesFilter?: boolean
      } = {
        from: "businessTransactions",
        dateFilter: params.dateFilterLabel || "all",
      }
      let isFilterApplied = false
      if (params.from_datetime) {
        isFilterApplied = true
        constructedPayload.from_datetime = params.from_datetime.toISOString()
      }
      if (params.to_datetime) {
        isFilterApplied = true
        constructedPayload.to_datetime = params.to_datetime.toISOString()
      }
      if (params.status) {
        isFilterApplied = true
        payloadForEvent.statusFilter = params.status
        constructedPayload.status = params.status
      }
      if (params.member) {
        isFilterApplied = true
        payloadForEvent.membersFilter = true
        if (params.member.businessUserId) {
          constructedPayload.businessUserId = params.member.businessUserId
        } else {
          constructedPayload.memberId = params.member.id
        }
      }
      if (params.party) {
        isFilterApplied = true
        payloadForEvent.partiesFilter = true
        constructedPayload.partyId = params.party.ids.toString()
      }
      if (params.attachments?.id) {
        isFilterApplied = true
        payloadForEvent.billProofFilter = params.attachments.id
        constructedPayload.attachments = params.attachments.id
      }
      if (params.categories?.id) {
        isFilterApplied = true
        constructedPayload.categoryId = params.categories.id
        payloadForEvent.categoriesFilter = true
      }
      if (isFilterApplied) {
        trackEvent(
          TrackingEvents.PAYMENT_TRANSACTIONS_FILTER_APPLIED,
          payloadForEvent
        )
      }
      const response = await getBusinessTransactions<{
        count: number
        data: PaymentsTransaction[]
        expenses: number
        collections: number
        totalCredit?: number
        totalDebit?: number
      }>({
        ...constructedPayload,
      })
      if (!response) {
        throw new Error("Something went wrong while fetching transactions!")
      }
      if (current === 1) {
        const transactionsDetails: TransactionDetails = {
          totalExpenses: response.expenses,
          totalCollections: response.collections,
          totalTransactions: response.count,
          transactions: response.data,
        }
        return dispatch({
          type: "FETCHED_TRANSACTIONS",
          payload: transactionsDetails,
        })
      }
      return dispatch({
        type: "FETCHED_MORE_TRANSACTIONS",
        payload: response.data,
      })
    } catch (e) {
      const error = e as Error
      if (current === 1) {
        return dispatch({
          type: "FETCHING_TRANSACTIONS_FAILED",
          payload: error,
        })
      }
      return toast.error(
        error.message || "Something went wrong while fetching transactions!"
      )
    }
  }, [businessId, current, pagination.skip, pagination.take, params])

  useEffect(() => {
    if (
      state.status === "in_progress" ||
      state.transactionDetails?.fetching ||
      state.transactionDetails?.fetchingMore
    ) {
      getTransactions()
    }
  }, [
    getTransactions,
    state.status,
    state.transactionDetails?.fetching,
    state.transactionDetails?.fetchingMore,
  ])

  function handleParamChange(key: string, value: unknown) {
    resetPagination()
    setFieldValue(key, value)
    dispatch({ type: "FETCHING_TRANSACTIONS_WITH_FILTERS_APPLIED" })
  }

  function handleDateChange(
    value: [Date | undefined, Date | undefined],
    label: string
  ) {
    resetPagination()
    setFieldValue("to_datetime", value[1])
    setFieldValue("from_datetime", value[0])
    setFieldValue("dateFilterLabel", label)
    dispatch({ type: "FETCHING_TRANSACTIONS_WITH_FILTERS_APPLIED" })
  }

  function reset() {
    setValues(initialTransactionFilters)
    resetPagination()
    dispatch({ type: "FETCHING_TRANSACTIONS_WITH_FILTERS_APPLIED" })
  }

  function retry() {
    setValues({})
    resetPagination()
    dispatch({ type: "FETCHING_TRANSACTIONS" })
  }

  function fetchMore() {
    if (current === lastPage) return
    next()
    dispatch({ type: "FETCHING_MORE_TRANSACTIONS" })
  }

  const areFiltersApplied: boolean = useMemo(() => {
    return Boolean(
      params.from_datetime ||
        params.to_datetime ||
        params.member?.id ||
        params.party?.id ||
        params.status ||
        params.attachments?.id ||
        params?.categories?.id
    )
  }, [params])

  return {
    error: state.error,
    status: state.status,
    fetching: state.transactionDetails?.fetching,
    transactions: state.transactionDetails?.transactions,
    totalExpenses: state.transactionDetails?.totalExpenses,
    totalCollections: state.transactionDetails?.totalCollections,
    transactionsCount: state.transactionDetails?.totalTransactions,
    paymentCollectionsEnabled: paymentCollectionsEnabled,

    //Filters
    params,
    areFiltersApplied,
    handleDateChange,
    handleParamChange,
    resetFilters: reset,
    refreshPage: retry,

    //Pagination
    current,
    lastPage,
    fetchingMore: state.transactionDetails?.fetchingMore,
    fetchMore,
  }
}

export function useAddPaymentCategory(businessId: string) {
  const [isLoading, setIsLoading] = useState(false)

  const addNewCategoryNetworkCall = useCallback(
    async (name: string) => {
      try {
        setIsLoading(true)
        const { data } = await addNewCategory<{
          data: any
        }>({
          businessId,
          name,
        })
        trackEvent(TrackingEvents.PAYMENT_CATEGORY_ADDED)
        const existingCategories =
          categoryListStore.getCategorySettings()[businessId]
        categoryListStore.updateList(businessId, [...existingCategories, data])
        return data
      } catch (e) {
        const err = e as Error
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [businessId]
  )

  return {
    isLoading,
    addNewCategoryNetworkCall,
  }
}

export function useUpdatePaymentCategory(businessId: string) {
  const [isLoading, setIsLoading] = useState(false)

  const updateCategoryNetworkCall = useCallback(
    async (name: string, categoryId: string) => {
      try {
        setIsLoading(true)
        const { data } = await updateCategory<{
          data: any
        }>({
          businessId,
          name,
          id: categoryId,
        })
        const existingCategories =
          categoryListStore.getCategorySettings()[businessId]
        const categoriesClone = [...existingCategories]
        trackEvent(TrackingEvents.PAYMENT_CATEGORY_RENAMED, {
          tagCount: data.tag_count,
        })
        existingCategories.forEach((category, index) => {
          if (category.id === categoryId) {
            categoriesClone[index] = {
              ...existingCategories[index],
              ...data,
            }
          }
        })
        categoryListStore.updateList(businessId, [...categoriesClone])
        return data
      } catch (e) {
        const err = e as Error
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [businessId]
  )

  return {
    isLoading,
    updateCategoryNetworkCall,
  }
}

export function useAssignCategory(
  businessId: string,
  from: "transactionDetails" | "businessTransactions" | "walletTransactions"
) {
  const [isLoading, setIsLoading] = useState(false)

  const assignCategoryNetworkCall = useCallback(
    async (
      npciTransactionIds: string[],
      categoryId: string,
      defaultCategoryId: string
    ) => {
      try {
        setIsLoading(true)
        const data = await assignCategory<any>({
          businessId,
          categoryId,
          npciTransactionIds,
          defaultCategoryId,
        })
        if (data.assignedCategoryId) {
          const existingCategories =
            categoryListStore.getCategorySettings()[businessId]
          const categoriesClone = [...existingCategories]
          existingCategories.forEach((category, index) => {
            if (
              defaultCategoryId &&
              category.default_id === defaultCategoryId
            ) {
              categoriesClone[index] = {
                ...existingCategories[index],
                id: data.assignedCategoryId,
              }
            }
          })
          if (categoryId || defaultCategoryId) {
            trackEvent(TrackingEvents.PAYMENT_CATEGORY_ASSIGNED, {
              isDefault: Boolean(defaultCategoryId),
              transactionCount: npciTransactionIds.length,
              from,
            })
          } else {
            trackEvent(TrackingEvents.PAYMENT_CATEGORY_REMOVED, {
              from,
              transactionCount: npciTransactionIds.length,
            })
          }
          categoryListStore.updateList(businessId, [...categoriesClone])
        }
        return data
      } catch (e) {
        const err = e as Error
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [businessId, from]
  )

  return {
    isLoading,
    assignCategoryNetworkCall,
  }
}

export function useDeleteCategory(businessId: string) {
  const [isLoading, setIsLoading] = useState(false)

  const deleteCategoryNetworkCall = useCallback(
    async (id: string, categoryId: string, defaultCategoryId: string) => {
      try {
        setIsLoading(true)
        const data = await deleteCategory<any>({
          businessId,
          categoryId,
          id,
          defaultCategoryId,
        })
        const existingCategories =
          categoryListStore.getCategorySettings()[businessId]
        let deletedCategoryTagCount = 0
        const categoriesClone = existingCategories.filter((category) => {
          if (category.id === id || category.default_id === id) {
            return false
          }
          deletedCategoryTagCount = category.tag_count
          return true
        })
        trackEvent(TrackingEvents.PAYMENT_CATEGORY_DELETED, {
          reassigned: Boolean(categoryId || defaultCategoryId),
          tagCount: deletedCategoryTagCount,
        })
        categoryListStore.updateList(businessId, [...categoriesClone])
        return data
      } catch (e) {
        const err = e as Error
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [businessId]
  )

  return {
    isLoading,
    deleteCategoryNetworkCall,
  }
}

export function useEnableDisableCategory(businessId: string) {
  const [isLoading, setIsLoading] = useState(false)

  const enableDisableCategoryNetworkCall = useCallback(
    async (
      action: "ENABLE" | "DISABLE",
      categoryId: string,
      defaultCategoryId: string
    ) => {
      try {
        setIsLoading(true)
        const data = await enableDisableSingleCategory<any>({
          businessId,
          categoryId,
          defaultCategoryId,
          action,
        })
        const existingCategories =
          categoryListStore.getCategorySettings()[businessId]
        const categoriesClone = [...existingCategories]
        trackEvent(TrackingEvents.PAYMENT_CATEGORY_VISIBILITY_UPDATED, {
          enabled: action === "DISABLE",
          tagCount: data.tag_count,
          isDefault: Boolean(data.default_id),
        })
        existingCategories.forEach((category, index) => {
          if (
            (categoryId && category.id === categoryId) ||
            (defaultCategoryId && category.default_id === defaultCategoryId)
          ) {
            categoriesClone[index] = {
              ...existingCategories[index],
              enabled: action === "ENABLE" ? true : false,
            }
          }
        })
        categoryListStore.updateList(businessId, [...categoriesClone])
        return data
      } catch (e) {
        const err = e as Error
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [businessId]
  )

  return {
    isLoading,
    enableDisableCategoryNetworkCall,
  }
}

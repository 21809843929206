import { useCallback, useState } from "react"
import { getSubscriptionPayments } from "./services"
import { SubscriptionInvoice } from "@cashbook/data-store/storage"
import { toast } from "react-hot-toast"

export function useSubscriptionInvoices(businessId: string) {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const [invoices, setInvoices] = useState<SubscriptionInvoice[]>([])

  const fetchInvoices = useCallback(async () => {
    try {
      setIsLoading(true)
      setError(null)
      const response = await getSubscriptionPayments<{
        count: number
        data: SubscriptionInvoice[]
      }>({
        businessId,
      })
      setInvoices(response.data)
    } catch (e) {
      const err = e as Error
      setError(err)
      toast.error(err.message || "Failed to fetch invoices")
    } finally {
      setIsLoading(false)
    }
  }, [businessId])

  return {
    isLoading,
    error,
    invoices,
    fetchInvoices,
  }
}

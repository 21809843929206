import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useFunctions,
  useUser,
} from "reactfire"
import { Timestamp } from "firebase/firestore"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { $Values } from "utility-types"
import type { CollectionReference } from "firebase/firestore"
import { collection, doc, query, where, orderBy } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { sleep } from "@cashbook/util-general"
import { useSyncedStorageState } from "@cashbook/data-store/storage"
import { TBusinessInvitation } from "./invitations"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"

export type Address = {
  buildingName?: string
  area: string
  pinCode: string
  state: string
  city?: string
}

export type Category = {
  id: BusinessCategoriesOrSubcategories
  subCategoryId?: string
  name?: string
}

//Referenced from mobile app
export type TBusiness = {
  createdAt: Timestamp
  createdByDefault: boolean
  details: {
    email?: string
    phoneNumber?: string
    address?: Address
    staff_size?: string
    category?: Category
    type?: BusinessTypes
    registrationType?: string
    gst?: {
      registered: boolean
      number: string
    }
  }
  name: string
  logo?: {
    image_url: string
    thumb_url: string
  }
  numBooks: number
  ownerId: string
  partners: string[]
  sharedWith: string[]
  id: string
  payments?: {
    enabled: boolean
    corpShortName: string
    entityId: string
  }
  activatedAt?: string
  preferences?: {
    can_enable_obo_payments: boolean
  }
}

export type TBusinessUser = {
  id: string
  name: string
  joinedAt?: Timestamp
  phoneNumber?: string
  email?: string
  can_enable_wallet?: boolean
  wallet?: {
    min_kyc: boolean // set after registering customer
    full_kyc: boolean // frontend to fetch further details if false
    created_at?: Timestamp
    paused_at?: Timestamp
    paused_by?: string
  }
  wallet_issued_by?: string
}

export type TBusinessMember = TBusinessUser & {
  uid: string
  role: $Values<typeof BUSINESS_ROLES_AND_PERMISSIONS>
}

export enum BUSINESS_PERMISSIONS {
  CHANGE_OWNER = "CHANGE_OWNER",
  LEAVE_BUSINESS = "LEAVE_BUSINESS",
  CHANGE_SETTINGS = "CHANGE_SETTINGS",
  DELETE_BUSINESS = "DELETE_BUSINESS",
  EDIT_BUSINESS_NAME = "EDIT_BUSINESS_NAME",
  EDIT_BUSINESS_DETAILS = "DELETE_ALL_ENTRIES",

  ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER",
  ADD_TO_BOOKS_AND_ASSIGN_ROLE = "ADD_TO_BOOKS_AND_ASSIGN_ROLE",
  REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER",
  CHANGE_ROLE = "CHANGE_ROLE",
  PROMOTE_STAFF_TO_PARTNER = "PROMOTE_STAFF_TO_PARTNER",

  ADD_BUSINESS_PARTNER = "ADD_BUSINESS_PARTNER",
  DEMOTE_PARTNER_TO_STAFF = "DEMOTE_PARTNER_TO_STAFF",
  REMOVE_BUSINESS_PARTNER = "REMOVE_BUSINESS_PARTNER",
  CANCEL_PARTNER_INVITATION = "CANCEL_PARTNER_INVITATION",

  DELETE_BOOK = "DELETE_BOOK",
  ADD_NEW_BOOK = "ADD_NEW_BOOK",
  DUPLICATE_BOOK = "DUPLICATE_BOOK",
  TRANSFER_ENTRIES = "TRANSFER_ENTRIES",
  UPDATE_ROLE_IN_BOOK = "UPDATE_ROLE_IN_BOOK",
  ADD_MEMBER_TO_BOOK = "ADD_MEMBER_TO_BOOK",
  REMOVE_MEMBER_FROM_BOOK = "REMOVE_MEMBER_FROM_BOOK",
  IMPORT_BOOK_FROM_BUSINESS = "IMPORT_BOOK_FROM_BUSINESS",
}

//Types ends here

function useBusinessesCollection() {
  const store = useFirestore()
  return collection(store, "Businesses") as CollectionReference<TBusiness>
}

export function useBusinessDocument(businessId: string) {
  const businessesCollection = useBusinessesCollection()
  return doc(businessesCollection, businessId)
}

export function useBusinessUsersCollection(businessId: string) {
  const businessDoc = useBusinessDocument(businessId)
  return collection(
    businessDoc,
    "BusinessUsers"
  ) as CollectionReference<TBusinessUser>
}

export function useBusinessUserDocument(
  businessId: string,
  businessUserId: string
) {
  const collection = useBusinessUsersCollection(businessId)
  return doc(collection, businessUserId)
}

export function useBusinesses() {
  const businessesCollection = useBusinessesCollection()
  const { data: user } = useUser()
  const businessesQuery = query(
    businessesCollection,
    where("sharedWith", "array-contains", user?.uid),
    orderBy("createdAt", "desc")
  )

  const { data: businesses } = useFirestoreCollectionData(businessesQuery, {
    idField: "id",
  })

  const getAuthMemberRoleForBusinessId = useCallback(
    (businessId: string) => {
      const business = businesses.find((b) => b.id === businessId)
      if (business && user?.uid) {
        const role = getBusinessRoleDetailsForMember(business, user?.uid)
        return role
      }
      return null
    },
    [businesses, user?.uid]
  )

  return {
    businesses,
    getAuthMemberRoleForBusinessId,
  }
}

/**
 * This hook will hold a reference to the business even after business deletion
 */
export function useEnsuredBusiness(businessId: string) {
  const businessDoc = useBusinessDocument(businessId)
  const { data: businessData } = useFirestoreDocData(businessDoc, {
    idField: "id",
  })
  // Create a ref of current data and pass it down.
  // This allows us to handle the business deletion
  const businessRef = useRef(businessData)
  let isDeleted = false
  if (businessData && businessData.name) {
    // always keep the data in sync
    businessRef.current = businessData
  } else {
    isDeleted = true
  }
  if (!businessRef.current) {
    throw new Error("Book not found")
  }
  return { business: businessRef.current, isDeleted }
}

export function checkIfTeamMemberCan(
  business: TBusiness,
  member: TBusinessMember | string | undefined,
  ...permissions: Array<BUSINESS_PERMISSIONS>
) {
  if (!member) return false
  let memberPermissions: Array<BUSINESS_PERMISSIONS> = []
  if (typeof member === "string") {
    memberPermissions = getBusinessRoleDetailsForMember(
      business,
      member
    ).permissions
  } else {
    memberPermissions = member.role.permissions
  }
  return permissions.every((p) => memberPermissions.indexOf(p) !== -1)
}

export function useBusiness(businessId: string) {
  const { business, isDeleted } = useEnsuredBusiness(businessId)
  const businessUsersCollection = useBusinessUsersCollection(businessId)

  const { data: user } = useUser()
  const { data: businessUsersData } = useFirestoreCollectionData(
    query(businessUsersCollection, orderBy("name", "asc")),
    { idField: "id" }
  )

  //Business Users = owner + partners + staff
  const businessUsers: Array<TBusinessUser> = useMemo(() => {
    if (!user) return []
    if (!businessUsersData.length)
      return [
        {
          id: user.uid,
          email: user.email || "",
          name: user.displayName || "You",
          phoneNumber: user.phoneNumber || "",
          joinedAt:
            user.metadata.creationTime && JSON.parse(user.metadata.creationTime)
              ? JSON.parse(user.metadata.creationTime)
              : undefined,
        },
      ]
    return businessUsersData
  }, [businessUsersData, user])

  //Shared with IDs
  const sharedWithIds = useMemo(
    () => business.sharedWith || (user ? [user.uid] : []),
    [business, user]
  )

  const businessRoleWiseTeamMembers = {
    owner: [],
    partner: [],
    staff: [],
  } as BusinessRoleWiseTeam

  const membersWithBusinessRolesAndPermissions: Array<TBusinessMember> =
    useMemo(() => {
      const resolveUsersFromBusinessUsers = (
        users: Array<string>
      ): Array<TBusinessUser & { uid: string }> => {
        return (users || [])
          .map((a) => {
            const data = businessUsers.find((u) => u.id === a)
            if (data) {
              return {
                ...data,
                uid: a,
              }
            }
            if (user && a === user.uid) {
              // resolve it from auth user
              return {
                id: a,
                name: user.displayName || "",
                phoneNumber: user.phoneNumber || "",
                uid: a,
              }
            }
            return undefined
          })
          .filter((a): a is TBusinessUser & { uid: string } => Boolean(a))
      }
      const sharedWithUsers = resolveUsersFromBusinessUsers(sharedWithIds)
      const teamMembers = sharedWithUsers.map((user) => {
        return {
          ...user,
          role: getBusinessRoleDetailsForMember(business, user.uid),
        }
      })
      // now sort the team members
      teamMembers.sort((a, b) => {
        // keep the authenticated member at the top
        if (user && a.uid === user.uid) return -1
        if (user && b.uid === user.uid) return 1
        // now prefer owners, partners, then staff.
        const roles = ["staff", "partner", "owner"]
        const aRoleIndex = roles.indexOf(a.role.id)
        const bRoleIndex = roles.indexOf(b.role.id)
        if (aRoleIndex !== bRoleIndex) {
          // prefer the owner roles
          return aRoleIndex > bRoleIndex ? -1 : 1
        }
        // same role, sort by name
        return a.name > b.name ? 1 : -1
      })
      return teamMembers
    }, [business, businessUsers, sharedWithIds, user])

  //Business Owner Details
  const businessOwner: TBusinessMember | null = useMemo(() => {
    const owner = membersWithBusinessRolesAndPermissions.find(
      (member) => member.uid === business.ownerId
    )
    if (!owner) return null
    return owner
  }, [business.ownerId, membersWithBusinessRolesAndPermissions])

  const authTeamMemberDetails: TBusinessMember = useMemo(() => {
    const member = membersWithBusinessRolesAndPermissions.find(
      (member) => member.uid === user?.uid
    )
    if (member) return member
    // This business might not have other members
    return {
      uid: user?.uid || "id",
      id: user?.uid || "id",
      email: user?.email || "",
      name: user?.displayName || "CashBook User",
      phoneNumber: user?.phoneNumber as "",
      // assign the role
      role:
        membersWithBusinessRolesAndPermissions.length <= 1
          ? getBusinessRoleDetails("owner")
          : getBusinessRoleDetails("staff"),
      joinedAt:
        user?.metadata.creationTime && JSON.parse(user.metadata.creationTime)
          ? JSON.parse(user.metadata.creationTime)
          : undefined,
    }
  }, [user, membersWithBusinessRolesAndPermissions])

  membersWithBusinessRolesAndPermissions.forEach((member) => {
    if (member.role.id === "owner") {
      businessRoleWiseTeamMembers["owner"].push(member)
    } else if (member.role.id === "partner") {
      businessRoleWiseTeamMembers["partner"].push(member)
    } else {
      businessRoleWiseTeamMembers["staff"].push(member)
    }
  })

  /**
   * Check if the currently authenticated user has requested permission(s)
   */
  const checkIfAuthenticatedTeamMemberCan = useCallback(
    (
      permission: BUSINESS_PERMISSIONS,
      ...permissions: Array<BUSINESS_PERMISSIONS>
    ) =>
      checkIfTeamMemberCan(
        business,
        authTeamMemberDetails,
        permission,
        ...permissions
      ),
    [business, authTeamMemberDetails]
  )

  const checkIfAuthenticatedTeamMemberCanDoOneOf = useCallback(
    (
      permission: BUSINESS_PERMISSIONS,
      ...permissions: Array<BUSINESS_PERMISSIONS>
    ) =>
      [permission]
        .concat(permissions)
        .some((permission) =>
          checkIfTeamMemberCan(business, authTeamMemberDetails, permission)
        ),
    [authTeamMemberDetails, business]
  )

  const getTeamMemberInfoForId = useCallback(
    (teamMemberId: string | undefined | null) => {
      if (!teamMemberId) return null
      const member = businessUsers.find((user) => user.id === teamMemberId)
      if (!member) return null
      return {
        ...member,
        isAuthMember: member.id === user?.uid,
        role: getBusinessRoleDetailsForMember(business, teamMemberId),
      } as unknown as TBusinessMember
    },
    [business, businessUsers, user?.uid]
  )

  return {
    business,
    isDeleted,
    businessOwner,
    sharedWithIds,
    authTeamMemberDetails,
    businessRoleWiseTeam: businessRoleWiseTeamMembers,
    businessTeam: membersWithBusinessRolesAndPermissions,

    //methods
    getTeamMemberInfoForId,
    checkIfAuthenticatedTeamMemberCan,
    checkIfAuthenticatedTeamMemberCanDoOneOf,
  }
}

export type BusinessRoleWiseTeam = {
  [key in T_AVAILABLE_BUSINESS_ROLES]: Array<TBusinessMember>
}

export function getBusinessRoleDetailsForMember(
  business: TBusiness,
  memberId: string
) {
  let role: T_AVAILABLE_BUSINESS_ROLES = "staff"
  if (memberId) {
    const ownerId = business.ownerId
    const partnerIds = business.partners || []
    const staffIds = business.sharedWith || []
    switch (true) {
      case memberId === ownerId:
        role = "owner"
        break
      case (partnerIds || []).indexOf(memberId) !== -1:
        role = "partner"
        break
      case (staffIds || []).indexOf(memberId) !== -1:
        role = "staff"
        break
      default:
        role = "staff"
    }
  }
  const details = getBusinessRoleDetails(
    role,
    role === "partner" && Boolean(business.payments?.enabled)
  )
  return details
}

export function getBusinessRoleDetails(
  role: keyof typeof BUSINESS_ROLES_AND_PERMISSIONS,
  showPaymentsPermission?: boolean
) {
  if (showPaymentsPermission) {
    const updatedPermissions = { ...BUSINESS_ROLES_AND_PERMISSIONS[role] }
    updatedPermissions.permissionsDescription = [
      ...BUSINESS_ROLES_AND_PERMISSIONS[role].permissionsDescription,
      ...PAYMENTS_RELATED_PERMISSIONS[role],
    ]
    return updatedPermissions
  }
  return BUSINESS_ROLES_AND_PERMISSIONS[role]
}

const PAYMENTS_RELATED_PERMISSIONS = {
  staff: ["Pay via UPI from issued wallet"],
  partner: [
    "Access to payments dashboard",
    "Send money to member wallets",
    "Pay via UPI from issued wallet",
  ],
  owner: ["Full access to payments dashboard"],
}

const BUSINESS_ROLES_AND_PERMISSIONS = {
  staff: {
    id: "staff" as const,
    title: "Staff",
    permissions: [
      BUSINESS_PERMISSIONS.LEAVE_BUSINESS,
      BUSINESS_PERMISSIONS.TRANSFER_ENTRIES,
      BUSINESS_PERMISSIONS.IMPORT_BOOK_FROM_BUSINESS,
    ],
    permissionsDescription: [
      "Limited access to selected books",
      "Owner/Partner can assign Admin, Viewer or Operator role to staff in any book",
    ],
    restrictionsDescription: [
      "No access to books they are not part of",
      "No access to business settings",
      "No option to delete books",
    ],
  },
  partner: {
    id: "partner" as const,
    title: "Partner",
    permissions: [
      BUSINESS_PERMISSIONS.DELETE_BOOK,
      BUSINESS_PERMISSIONS.ADD_NEW_BOOK,
      BUSINESS_PERMISSIONS.DUPLICATE_BOOK,
      BUSINESS_PERMISSIONS.LEAVE_BUSINESS,
      BUSINESS_PERMISSIONS.ADD_TEAM_MEMBER,
      BUSINESS_PERMISSIONS.CHANGE_SETTINGS,
      BUSINESS_PERMISSIONS.TRANSFER_ENTRIES,
      BUSINESS_PERMISSIONS.REMOVE_TEAM_MEMBER,
      BUSINESS_PERMISSIONS.ADD_BUSINESS_PARTNER,
      BUSINESS_PERMISSIONS.EDIT_BUSINESS_DETAILS,
      BUSINESS_PERMISSIONS.CHANGE_ROLE,
      BUSINESS_PERMISSIONS.PROMOTE_STAFF_TO_PARTNER,
      BUSINESS_PERMISSIONS.IMPORT_BOOK_FROM_BUSINESS,
      BUSINESS_PERMISSIONS.ADD_TO_BOOKS_AND_ASSIGN_ROLE,

      BUSINESS_PERMISSIONS.ADD_MEMBER_TO_BOOK,
      BUSINESS_PERMISSIONS.UPDATE_ROLE_IN_BOOK,
      BUSINESS_PERMISSIONS.REMOVE_MEMBER_FROM_BOOK,
    ],
    permissionsDescription: [
      "Full access to all books of this business",
      "Full access to business settings",
      "Add/remove members in business",
    ],
    restrictionsDescription: [
      "Can’t delete business",
      "Can’t remove owner from business",
    ],
  },
  owner: {
    id: "owner" as const,
    title: "Owner",
    permissions: [
      BUSINESS_PERMISSIONS.DELETE_BOOK,
      BUSINESS_PERMISSIONS.ADD_NEW_BOOK,
      BUSINESS_PERMISSIONS.CHANGE_OWNER,
      BUSINESS_PERMISSIONS.DUPLICATE_BOOK,
      BUSINESS_PERMISSIONS.ADD_TEAM_MEMBER,
      BUSINESS_PERMISSIONS.CHANGE_SETTINGS,
      BUSINESS_PERMISSIONS.DELETE_BUSINESS,
      BUSINESS_PERMISSIONS.TRANSFER_ENTRIES,
      BUSINESS_PERMISSIONS.EDIT_BUSINESS_NAME,
      BUSINESS_PERMISSIONS.REMOVE_TEAM_MEMBER,
      BUSINESS_PERMISSIONS.ADD_BUSINESS_PARTNER,
      BUSINESS_PERMISSIONS.EDIT_BUSINESS_DETAILS,
      BUSINESS_PERMISSIONS.DEMOTE_PARTNER_TO_STAFF,
      BUSINESS_PERMISSIONS.PROMOTE_STAFF_TO_PARTNER,
      BUSINESS_PERMISSIONS.CANCEL_PARTNER_INVITATION,
      BUSINESS_PERMISSIONS.IMPORT_BOOK_FROM_BUSINESS,
      BUSINESS_PERMISSIONS.CHANGE_ROLE,
      BUSINESS_PERMISSIONS.ADD_TO_BOOKS_AND_ASSIGN_ROLE,
      BUSINESS_PERMISSIONS.REMOVE_BUSINESS_PARTNER,

      //Book level
      BUSINESS_PERMISSIONS.ADD_MEMBER_TO_BOOK,
      BUSINESS_PERMISSIONS.UPDATE_ROLE_IN_BOOK,
      BUSINESS_PERMISSIONS.REMOVE_MEMBER_FROM_BOOK,
    ],
    permissionsDescription: [
      "Full access to all books of this business",
      "Full access to business settings",
      "Add/remove members in business",
    ],
    restrictionsDescription: [],
  },
}

export type T_AVAILABLE_BUSINESS_ROLES =
  keyof typeof BUSINESS_ROLES_AND_PERMISSIONS

export type UPDATE_BUSINESS_USER_ACTIONS =
  | "add_books"
  | "remove_book"
  | "remove_business"
  | "book_role_change"
  | "business_role_change"

export type UserOrPhoneNumberRequired =
  | {
      userId: string
    }
  | { email?: string; phoneNumber?: string }

export type OperationalBookRoles = "admin" | "editor" | "viewer"
export type SharedBooksForAddToBooks = Array<{
  id: string
  role: OperationalBookRoles
}>

type AddToBooks = UserOrPhoneNumberRequired & {
  businessId: string
  sharedBooks: SharedBooksForAddToBooks
}
type RemoveFromBook = UserOrPhoneNumberRequired & {
  bookId: string
}

type ChangeRoleInBook = UserOrPhoneNumberRequired & {
  bookId: string
  role: OperationalBookRoles
}

type RemoveUserFromBusiness = UserOrPhoneNumberRequired & {
  businessId: string
}

type ChangeBusinessLevelRole = UserOrPhoneNumberRequired & {
  businessId: string
  role: "partner" | "staff"
}

export type BUSINESS_USER_UPDATE_PAYLOAD =
  | AddToBooks
  | RemoveFromBook
  | ChangeRoleInBook
  | RemoveUserFromBusiness
  | ChangeBusinessLevelRole

export function useUpdateBusinessUser(type: UPDATE_BUSINESS_USER_ACTIONS) {
  const fns = useFunctions()
  return useCallback(
    async (values: BUSINESS_USER_UPDATE_PAYLOAD) => {
      const { data } = await httpsCallable<
        BUSINESS_USER_UPDATE_PAYLOAD & {
          type: UPDATE_BUSINESS_USER_ACTIONS
        }
      >(
        fns,
        "updateBusinessUser"
      )({
        type,
        ...values,
      })
      if (!data) {
        throw new Error("Something went wrong. Try again later.")
      }
      await sleep(1000)
      return data
    },
    [fns, type]
  )
}

export function getAllBusinessRolesWithPermissions() {
  return Object.keys(BUSINESS_ROLES_AND_PERMISSIONS).map((key) => ({
    ...BUSINESS_ROLES_AND_PERMISSIONS[key as T_AVAILABLE_BUSINESS_ROLES],
    role: key,
  }))
}

export function useAddTeamMember() {
  const fns = useFunctions()
  return useCallback(
    async (memberDetails: {
      guestPhone?: string
      guestEmail?: string
      guestName: string
      businessId: string
      bookId?: string
      bookRole?: OperationalBookRoles
      role: Omit<T_AVAILABLE_BUSINESS_ROLES, "owner">
      can_enable_wallet?: boolean
    }) => {
      return httpsCallable<
        typeof memberDetails,
        TBusinessInvitation & { message: string }
      >(
        fns,
        "addUserToBusiness"
      )({ ...memberDetails })
    },
    [fns]
  )
}

export function useLeaveBusiness(businessId: string) {
  const fns = useFunctions()
  return useCallback(
    async function leaveBusiness() {
      const {
        data: { status },
      } = await httpsCallable<{ businessId: string }, { status: string }>(
        fns,
        "leaveBusiness"
      )({
        businessId: businessId,
      })
      if (status === "success") {
        return true
      }
      throw new Error(
        "Can not leave the business at the moment. Please try after some time"
      )
    },
    [businessId, fns]
  )
}

export function useChangeBusinessOwner(businessId: string) {
  const fns = useFunctions()
  return useCallback(
    async function changeBusinessOwner(id: string) {
      const { data } = await httpsCallable<
        { id: string; businessId: string },
        string
      >(
        fns,
        "transferBusinessOwner"
      )({
        id,
        businessId,
      })
      if (data.length) {
        return true
      }
      throw new Error(
        "Can not leave the business at the moment. Please try after some time"
      )
    },
    [businessId, fns]
  )
}

export type BusinessCategoriesOrSubcategories =
  | "others"
  | "jewellery"
  | "transport"
  | "hardware"
  | "education"
  | "electronics"
  | "agriculture"
  | "construction"
  | "kiranaGrocery"
  | "foodRestaurant"
  | "financialServices"
  | "garmentsFashion"
  | "healthcareFitness"

export type BusinessTypes =
  | "retailer"
  | "distributor"
  | "manufacturer"
  | "serviceProvider"
  | "trader"
  | "others"

export type CreateBusinessPayload = {
  name: string
  details?: {
    category?: Category
    type?: string
  }
}
export function useCreateBusiness() {
  const fns = useFunctions()
  return useCallback(
    async function createBusiness(values: CreateBusinessPayload) {
      const {
        data: { id },
      } = await httpsCallable<CreateBusinessPayload, { id: string }>(
        fns,
        "createBusiness"
      )({
        ...values,
      })
      trackEvent(TrackingEvents.NEW_BUSINESS_ADDED, {
        businessName: values.name,
        businessCategory: values.details?.category?.id || "",
        businessType: values.details?.type || "",
      })
      if (!id) {
        throw new Error(
          "Can not create business at the moment. Please try after some time"
        )
      }
      return { businessId: id }
    },
    [fns]
  )
}

export function useDeleteBusiness(businessId: string) {
  const fns = useFunctions()
  return useCallback(
    async function deleteBusiness() {
      const { data } = await httpsCallable<
        { businessId: string },
        { id: string }
      >(
        fns,
        "deleteBusiness"
      )({
        businessId,
      })
      if (!data) {
        throw new Error(
          "Can not delete business at the moment. Please try after some time"
        )
      }
      return true
    },
    [fns, businessId]
  )
}

type SavedBusinesses = { [key: string]: boolean }
export function useNewBusinesses() {
  const { data: user } = useUser()
  const { businesses } = useBusinesses()
  const [initiallyRendered, setInitiallyRendered] = useState<boolean>(false)
  const [businessesSaved, setBusinessesSaved] =
    useSyncedStorageState<SavedBusinesses>("businesses", {})

  useEffect(() => {
    if (!initiallyRendered) {
      setInitiallyRendered(true)
      const payload: SavedBusinesses = {}
      businesses.forEach((business) => {
        if (user?.uid && business.ownerId !== user?.uid) {
          payload[business.id] = true
        }
      })
      setBusinessesSaved(payload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiallyRendered])

  const businessIds = useMemo(() => {
    if (!user?.uid.length) return [""]
    const ids = businesses.map((b) => b.id)
    return ids
  }, [businesses, user?.uid])

  function convertSavedBusinessIntoOldBusinesses(): string[] {
    const transitionedBusinesses = Object.keys(businessesSaved).filter(
      (key) => businessesSaved[key]
    )
    return transitionedBusinesses
  }

  useEffect(() => {
    if (
      convertSavedBusinessIntoOldBusinesses().length < businessIds.length &&
      initiallyRendered
    ) {
      const payload: SavedBusinesses = businessesSaved
      businesses.forEach((business) => {
        if (
          user?.uid &&
          business.ownerId !== user?.uid &&
          payload[business.id] === undefined &&
          !payload[business.id]
        ) {
          payload[business.id] = false
        }
      })
      setBusinessesSaved(payload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessIds.length])

  function updateToOldBusiness(businessId: string) {
    setBusinessesSaved({ ...businessesSaved, [businessId]: true })
  }

  function removeFromBusinesses(businessId: string) {
    const payload = businessesSaved
    delete payload[businessId]
    setBusinessesSaved({ ...payload })
  }
  return {
    businessesSaved,
    updateToOldBusiness,
    removeFromBusinesses,
  }
}

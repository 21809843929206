import {
  Button,
  DocumentDownloadIcon,
  Modal,
  ModalBody,
  ModalFooter,
  SpinnerIcon,
  Stack,
  Text,
} from "@cashbook/web-components"
import { useOverlayTriggerState } from "@react-stately/overlays"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  StaffWalletReportType,
  getStaffWalletTransactionsReport,
} from "@cashbook/data-store/payments"

function ExportStaffWalletTransactionsInModal({
  children,
  businessId,
  uid,
  name,
}: {
  children: ({
    onExport,
  }: {
    onExport: (type: StaffWalletReportType) => void
  }) => JSX.Element
  businessId: string
  uid: string
  name: string
}) {
  const state = useOverlayTriggerState({})
  const [status, setStatus] = useState<"idle" | "in_progress">("idle")
  const [exportType, setExportType] = useState<StaffWalletReportType>("pdf")

  async function downloadFile() {
    setStatus("in_progress")
    trackEvent(TrackingEvents.DOWNLOAD_STAFF_WALLET_STATEMENT_REPORT, {
      reportType: exportType,
    })
    try {
      const response = await getStaffWalletTransactionsReport<Blob>({
        businessId,
        memberId: uid,
        reportType: exportType,
      })

      if (!response) {
        throw new Error("Failed to download report")
      }

      const url = window.URL.createObjectURL(response)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute(
        "download",
        `${
          name?.toLowerCase().replace(/\s+/g, "-") || "wallet"
        }-transactions-${new Date().toISOString()}.${
          exportType === "csv" ? "xlsx" : exportType
        }`
      )
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      toast.success(
        `${exportType === "csv" ? "Excel" : "PDF"} downloaded successfully`
      )
      state.close()
    } catch (error) {
      toast.error("Failed to download report")
    } finally {
      setStatus("idle")
    }
  }

  function onExport(type: StaffWalletReportType) {
    setExportType(type)
    state.open()
  }

  const userName = name?.split(" ")[0] || "member"

  return (
    <>
      {children({ onExport })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        isDismissable
        size="lg"
        title={`Export ${userName}'s Wallet Transactions`}
      >
        <ModalBody>
          <Stack gap="4">
            <Text>
              Download {exportType === "pdf" ? "PDF" : "Excel"} report for{" "}
              {userName}'s wallet transactions
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          {status === "in_progress" ? (
            <Button status="primary" disabled size="lg">
              <SpinnerIcon /> Please wait...
            </Button>
          ) : (
            <Button level="primary" autoFocus size="lg" onClick={downloadFile}>
              <DocumentDownloadIcon /> Download{" "}
              {exportType === "csv" ? "Excel" : exportType.toUpperCase()}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ExportStaffWalletTransactionsInModal

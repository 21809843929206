import { Link, useParams } from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import {
  AddMoneyIcon,
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  CBButton,
  CancelIcon,
  CardDeactivatedIcon,
  CardPendingIcon,
  Circle,
  DataLoadingFallback,
  GradientCardIcon,
  Inline,
  PageMeta,
  PlusIcon,
  SearchIcon,
  SearchSelect,
  Stack,
  Text,
  WalletDeactivated,
} from "@cashbook/web-components"
import { Avatar, GeneralErrorHandler } from "../Payments"
import {
  Wallet,
  WalletSortMethods,
  useStaffWallets,
} from "@cashbook/data-store/payments"
import { Amount } from "../support/Intl"
import { NotInWeb } from "../support"
import {
  showPaymentsStore,
  userWalletStore,
} from "@cashbook/data-store/storage"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { IssueWalletInModal } from "../Payments/StaffWallet"
import { InActiveWalletCard } from "./PaymentsDashboardPage"
import { useSyncExternalStore } from "react"

export default function StaffWalletsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading wallets details..." />}
        traceId="loading_wallets_details"
      >
        <StaffWallets key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const optionsForSortBy: { [key in WalletSortMethods]: string } = {
  byBalanceAsc: "Balance (Low to High)",
  byBalanceDesc: "Balance (High to Low)",
  byNameAsc: "Name (A to Z)",
  byNameDesc: "Name (Z to A)",
  byLastCreated: "Last Issued",
}

function StaffWallets({ businessId }: { businessId: string }) {
  const {
    error,
    params,
    business,
    allWallets,
    teamWallets,
    currentUserWallet,
    refreshPage,
    setFieldValue,
    handleParamChange,
  } = useStaffWallets(businessId)

  const showPayments = useSyncExternalStore(
    showPaymentsStore.subscribe,
    showPaymentsStore.getShowPaymentsList
  )

  return (
    <>
      <PageMeta>
        <title>Member Wallets (Payments) - {business.name}</title>
      </PageMeta>
      {error ? (
        <Box height="full">
          <GeneralErrorHandler onRetry={refreshPage} />
        </Box>
      ) : (
        <Inline height="full" backgroundColor="backgroundLight2">
          <Box
            padding="8"
            width="full"
            maxWidth={{ xl: "3xl", lg: "2xl" }}
            className="lg:border-r-[1px] xl:min-w-[760px]"
            borderColor="borderOutline"
          >
            {allWallets.length ? (
              <Stack gap="6">
                <Box className="lg:hidden block">
                  {currentUserWallet.kycStatus === "full_kyc" ? (
                    <YourWallet
                      currentUserWallet={currentUserWallet}
                      onWalletClick={() => {
                        userWalletStore.setUserWallet(currentUserWallet)
                      }}
                      isWalletPaused={Boolean(
                        allWallets.find(
                          (outerWallet) =>
                            outerWallet?.id === currentUserWallet?.uid
                        )?.wallet?.paused_at
                      )}
                    />
                  ) : showPayments[businessId]?.can_enable_wallet ? (
                    <NotInWeb heading="Use mobile app to activate your wallet">
                      {({ onDisplay }) => (
                        <InActiveWalletCard onClick={onDisplay} />
                      )}
                    </NotInWeb>
                  ) : null}
                </Box>
                <Inline
                  gap="6"
                  width="full"
                  flexDirection={{ xl: "row", xs: "col" }}
                  alignItems={{ xl: "center", xs: "start" }}
                >
                  <Inline
                    position="relative"
                    rounded="md"
                    height="10"
                    paddingRight="2"
                    alignItems="stretch"
                    gap="6"
                    borderWidth="1"
                    backgroundColor="backgroundLight1"
                    width="full"
                    borderColor="borderOutline"
                    className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
                  >
                    <input
                      type="search"
                      name="q"
                      autoComplete="off"
                      placeholder="Search by name or number"
                      value={params.q}
                      onChange={handleParamChange}
                      className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                    />
                    <Inline
                      as="button"
                      type="button"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => {
                        if (params.q) setFieldValue("q", "")
                      }}
                    >
                      {params.q ? (
                        <CancelIcon color="gray900" />
                      ) : (
                        <SearchIcon color="gray500" />
                      )}
                    </Inline>
                  </Inline>
                  <Box className="min-w-fit">
                    <SearchSelect
                      searchDisabled
                      hasValue={
                        params.sortBy === "byBalanceAsc"
                          ? false
                          : Boolean(params.sortBy)
                      }
                      onChange={(option) => {
                        setFieldValue(
                          "sortBy",
                          option ? option.id : "byBalanceAsc"
                        )
                      }}
                      options={Object.keys(optionsForSortBy).map((key) => {
                        return {
                          id: key,
                          label: optionsForSortBy[key as WalletSortMethods],
                        }
                      })}
                      value={params.sortBy}
                      height="10"
                      label={optionsForSortBy[params.sortBy]}
                    />
                  </Box>
                </Inline>
                <Stack as="ul" gap="4">
                  <Box as="li" key="issueWallet" className="lg:hidden block">
                    <IssueWalletInModal businessId={businessId}>
                      {({ open }) => (
                        <Inline
                          rounded="md"
                          padding="4"
                          borderWidth="1"
                          alignItems="center"
                          gap="4"
                          onClick={open}
                          borderColor="borderOutline"
                          backgroundColor="surfaceDefault"
                        >
                          <Circle size="10">
                            <PlusIcon />
                          </Circle>
                          <Text fontSize="s3">Issue New Wallet</Text>
                        </Inline>
                      )}
                    </IssueWalletInModal>
                  </Box>
                  {teamWallets.length ? (
                    teamWallets.map((wallet, i) => (
                      <Box key={i} as="li">
                        <MemberWalletItem
                          wallet={wallet}
                          onClick={() => {
                            userWalletStore.setUserWallet(wallet)
                          }}
                          to={`${
                            wallet.deactivation ? wallet.walletId : wallet.uid
                          }?header=none`}
                          isWalletPaused={Boolean(
                            allWallets.find(
                              (outerWallet) => outerWallet?.id === wallet?.uid
                            )?.wallet?.paused_at
                          )}
                        />
                      </Box>
                    ))
                  ) : params.q?.length ? (
                    <Stack
                      height="full"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      paddingY="24"
                      gap="2"
                    >
                      <Text fontSize="s1">
                        No wallet found for ‘{params.q}’
                      </Text>
                      <Text fontSize="b3" color="textMedium">
                        Clear your search & try again
                      </Text>
                    </Stack>
                  ) : (
                    <Stack
                      height="full"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      paddingY="24"
                      gap="8"
                    >
                      <Stack gap="2">
                        <Text fontSize="s1">No wallet issued</Text>
                        <Text fontSize="b3" color="textMedium">
                          Issue wallets to staff & partner
                        </Text>
                      </Stack>
                      <IssueWalletInModal businessId={businessId}>
                        {({ open }) => (
                          <CBButton
                            iconPlacement="left"
                            level="primary"
                            onClick={open}
                          >
                            <PlusIcon />
                            Issue Now
                          </CBButton>
                        )}
                      </IssueWalletInModal>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            ) : (
              <Stack
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                height="full"
                gap="2"
              >
                <Text fontSize="s1">No Wallets issued</Text>
                <Text fontSize="b3" color="textMedium">
                  Issue Wallets to staff & partner
                </Text>
                <Box paddingTop="6">
                  <NotInWeb heading="Use mobile app to use this feature">
                    {({ onDisplay }) => (
                      <Button
                        level="primary"
                        onClick={() => {
                          onDisplay()
                          trackEvent(TrackingEvents.ISSUE_WALLET_CLICKED, {
                            existingWalletCount: teamWallets.length,
                            from: "allMemberWalletsScreen",
                          })
                        }}
                      >
                        <PlusIcon />
                        Issue Wallets Now
                      </Button>
                    )}
                  </NotInWeb>
                </Box>
              </Stack>
            )}
          </Box>
          <Box
            width="full"
            className="lg:block hidden"
            maxWidth={{ xl: "md", lg: "xs" }}
            padding="8"
          >
            <Stack gap="4">
              <Inline
                gap="4"
                backgroundColor="backgroundLight1"
                padding="4"
                rounded="md"
                borderWidth="1"
                borderColor="borderOutline"
              >
                <GradientCardIcon size="10" />
                <Stack gap="4">
                  <Stack gap="1">
                    <Text fontSize="s3">Issue New Wallet</Text>
                    <Text fontSize="c2" color="textMedium">
                      Issue wallet to staff & partners
                    </Text>
                  </Stack>
                  <IssueWalletInModal businessId={businessId}>
                    {({ open }) => (
                      <Button level="primary" onClick={open}>
                        <PlusIcon />
                        Issue Now
                      </Button>
                    )}
                  </IssueWalletInModal>
                </Stack>
              </Inline>
              {currentUserWallet.kycStatus === "full_kyc" ? (
                <YourWallet
                  currentUserWallet={currentUserWallet}
                  onWalletClick={() => {
                    userWalletStore.setUserWallet(currentUserWallet)
                  }}
                  isWalletPaused={Boolean(
                    allWallets.find(
                      (outerWallet) =>
                        outerWallet?.id === currentUserWallet?.uid
                    )?.wallet?.paused_at
                  )}
                />
              ) : showPayments[businessId]?.can_enable_wallet ? (
                <NotInWeb heading="Use mobile app to activate your wallet">
                  {({ onDisplay }) => (
                    <InActiveWalletCard onClick={onDisplay} />
                  )}
                </NotInWeb>
              ) : null}
            </Stack>
          </Box>
        </Inline>
      )}
    </>
  )
}

function MemberWalletItem({
  to,
  wallet,
  onClick,
  isWalletPaused,
}: {
  to: string
  wallet: Wallet
  onClick?: () => void
  isWalletPaused: boolean
}) {
  return (
    <Inline
      backgroundColor="surfaceDefault"
      borderWidth="1"
      rounded="md"
      padding="4"
      justifyContent="between"
      alignItems="start"
      gap="4"
      as={Link}
      to={to}
      onClick={onClick}
      borderColor="borderOutline"
      className="hover:bg-blue-5"
    >
      <Inline gap="2">
        {wallet.kycStatus !== "full_kyc" ||
        wallet.deactivation ||
        isWalletPaused ? (
          <Circle
            size="10"
            backgroundColor={
              wallet?.deactivation
                ? "surfaceNeutralLowest"
                : "surfaceWarningLowest"
            }
          >
            {wallet.deactivation ? (
              <CardDeactivatedIcon color="iconMedium" />
            ) : isWalletPaused ? (
              <WalletDeactivated color="iconWarning" />
            ) : (
              <CardPendingIcon color="iconWarning" />
            )}
          </Circle>
        ) : (
          <Avatar size="6" fontSize="c2" id={wallet.uid} name={wallet.name} />
        )}
        <Stack gap="2">
          <Text fontSize="s4" className="max-w-full line-clamp-1">
            {wallet.name}
          </Text>
          <Text color="textMedium" fontSize="c2">
            {wallet.phoneNumber}
          </Text>
        </Stack>
      </Inline>
      {wallet.deactivation ? (
        <Box
          backgroundColor={"surfaceNeutralLowest"}
          paddingX="2"
          paddingY="1"
          rounded="md"
        >
          <Text fontSize="c1" color="textMedium">
            Deactivated!
          </Text>
        </Box>
      ) : isWalletPaused ? (
        <Box
          backgroundColor={"surfaceWarningLowest"}
          paddingX="2"
          paddingY="1"
          rounded="md"
        >
          <Text fontSize="c1" color="textWarning">
            Wallet Paused
          </Text>
        </Box>
      ) : wallet.kycStatus === "init" ? (
        <Box
          backgroundColor={"surfaceWarningLowest"}
          paddingX="2"
          paddingY="1"
          rounded="md"
        >
          <Text fontSize="c1" color="textWarning">
            KYC Pending
          </Text>
        </Box>
      ) : wallet.kycStatus === "min_kyc" ? (
        <Box
          backgroundColor={"surfaceWarningLowest"}
          paddingX="2"
          paddingY="1"
          rounded="md"
        >
          <Text fontSize="c1" color="textWarning">
            Full KYC Pending
          </Text>
        </Box>
      ) : (
        <Stack gap="2" alignItems="end">
          <Amount
            amount={wallet.balance || 0}
            fontSize="s4"
            color={wallet?.balance === 0 ? "textCashOut" : undefined}
          />
          <Text color="textMedium">Balance</Text>
        </Stack>
      )}
    </Inline>
  )
}

function YourWallet({
  currentUserWallet,
  onWalletClick,
  isWalletPaused,
}: {
  currentUserWallet: Wallet
  onWalletClick: () => void
  isWalletPaused: boolean
}) {
  return (
    <Box as={Link} to={currentUserWallet.uid + "?header=none"}>
      <Box
        rounded="md"
        borderWidth="1"
        backgroundColor="surfaceDefault"
        borderColor="borderOutline"
        className="hover:bg-blue-5"
        onClick={onWalletClick}
      >
        <Inline
          justifyContent="between"
          alignItems="center"
          padding="4"
          borderBottomWidth="1"
          borderColor="borderOutline"
        >
          <Inline gap="4">
            <Avatar size="10" id={currentUserWallet.uid} name="Your Wallet" />
            <Stack gap="1">
              <Text fontSize="s3">Your Wallet</Text>
              <Text fontSize="c2" color="textMedium">
                {currentUserWallet.phoneNumber}
              </Text>
            </Stack>
          </Inline>
          <ArrowRightIcon />
        </Inline>
        <Box paddingX="4" marginTop="5">
          {isWalletPaused ? (
            <Alert status="warning" marginBottom="0" borderWidth="0">
              <Text fontSize="c2">This wallet is paused!</Text>
            </Alert>
          ) : null}
        </Box>
        <Stack
          gap="6"
          flexDirection={{ lg: "col", xs: "row" }}
          paddingY="5"
          paddingX="4"
          textAlign="center"
          justifyContent="between"
        >
          <Stack gap="2">
            <Text fontSize="c2" color="textMedium">
              Wallet Balance
            </Text>
            <Amount
              currency="inr"
              fontSize="s3"
              amount={currentUserWallet.balance || 0}
            />
          </Stack>
          {!isWalletPaused ? (
            <NotInWeb heading="Use mobile app to use this feature">
              {({ onDisplay }) => (
                <Button
                  size="lg"
                  status="success"
                  onClick={(e) => {
                    e.preventDefault()
                    onDisplay()
                    trackEvent(TrackingEvents.ADD_MONEY_CLICKED, {
                      from: "allMemberWalletsScreen",
                    })
                  }}
                >
                  <AddMoneyIcon />
                  Add Money
                </Button>
              )}
            </NotInWeb>
          ) : null}
        </Stack>
      </Box>
    </Box>
  )
}

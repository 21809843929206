import {
  DataLoadingFallback,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  NavLink,
} from "@cashbook/web-components"
import { Navigate, useParams, Outlet } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"

export function RedirectToPaymentCategoriesFromPaymentSettingsPage({
  routePrefix = "",
}: {
  routePrefix?: string
}) {
  const { businessId } = useParams()
  if (!businessId) return <Navigate to={`${routePrefix}/`} />
  return (
    <Navigate
      to={`${routePrefix}/businesses/${businessId}/payments/settings/categories`}
      replace
    />
  )
}

export default function PaymentsSettingsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <PaymentSettingsLayout />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function PaymentSettingsLayout() {
  return (
    <>
      <Box bgColor="white" className="min-h-screen sm:min-h-0">
        <Box display="flex">
          <Box
            as="aside"
            className="w-[264px]"
            borderRightWidth="1"
            borderColor="gray100"
          >
            <Box as="ol" paddingLeft="4" className="divide-y">
              {[
                {
                  label: "Manage Category",
                  description: "Add & manage categories",
                  to: "categories",
                },
              ].map(({ label, description, to }) => {
                return (
                  <Box as="li" paddingY="3" paddingRight="4" key={label}>
                    <NavLink
                      to={to}
                      paddingX="4"
                      paddingY="3"
                      display="block"
                      rounded="md"
                      bgColor={{ default: "transparent", hover: "blue50" }}
                      activeProps={{
                        bgColor: "blue100",
                      }}
                    >
                      <Stack gap="2">
                        <Inline alignItems="center" gap="2">
                          <Heading
                            as="h4"
                            fontWeight="semibold"
                            fontSize="base"
                            className="relative"
                          >
                            {label}
                          </Heading>
                        </Inline>
                        <Text color="gray500" fontWeight="medium" fontSize="sm">
                          {description}
                        </Text>
                      </Stack>
                    </NavLink>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box flexGrow="1">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  )
}

import { TTransaction } from "@cashbook/data-store/books"
import { Options as ApiConfig, callApiService } from "./api"
import endpoints from "./endpoints"

export type MasterWalletTransactionsPayload = {
  businessId: string
  skip: number
  take: number
  transaction_type?: string
  from_datetime?: string
  to_datetime?: string
}

export function getMasterWalletTransactions<T>({
  transaction_type,
  from_datetime,
  to_datetime,
  businessId,
  skip,
  take,
  ...rest
}: MasterWalletTransactionsPayload & ApiConfig): Promise<T> {
  const payload: MasterWalletTransactionsPayload = {
    businessId,
    skip,
    take,
  }
  if (transaction_type) {
    payload["transaction_type"] = transaction_type
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.masterWalletTransactions,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type MasterWalletReportExportType = "pdf" | "csv"

export function getMasterWalletTransactionsReport<T>({
  transaction_type,
  from_datetime,
  to_datetime,
  businessId,
  reportType,
  ...rest
}: Omit<MasterWalletTransactionsPayload, "skip" | "take"> & {
  reportType: MasterWalletReportExportType
} & ApiConfig): Promise<T> {
  const payload: Omit<MasterWalletTransactionsPayload, "skip" | "take"> & {
    reportType: MasterWalletReportExportType
  } = {
    businessId,
    reportType,
  }
  if (transaction_type) {
    payload["transaction_type"] = transaction_type
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  if (reportType) {
    payload["reportType"] = reportType
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.masterWalletTransactionsReport,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type BusinessTransactionsPayload = {
  businessId: string
  memberId?: string
  from_datetime?: string
  to_datetime?: string
  skip: number
  take: number
  partyId?: string
  status?: "SUCCESS" | "PENDING" | "REFUNDED"
  attachments?: "attached" | "missing"
  businessUserId?: string
  categoryId?: string
}
export function getBusinessTransactions<T>({
  from_datetime,
  to_datetime,
  status,
  memberId,
  businessId,
  partyId,
  skip,
  take,
  businessUserId,
  attachments,
  categoryId,
  ...rest
}: BusinessTransactionsPayload & ApiConfig): Promise<T> {
  const payload: BusinessTransactionsPayload = {
    businessId,
    skip,
    take,
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  if (status) {
    payload["status"] = status
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  if (businessUserId) {
    payload["businessUserId"] = businessUserId
  }
  if (businessId)
    if (partyId) {
      payload["partyId"] = partyId
    }
  if (attachments) {
    payload["attachments"] = attachments
  }
  if (categoryId) {
    payload["categoryId"] = categoryId
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.businessTransactions,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export function getMasterWalletBalance<T>(
  businessId: string,
  options?: ApiConfig
): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.masterWalletBalance,
    options: {
      payload: { businessId },
      ...options,
    },
  }) as Promise<T>
}

export function getAllParties<T>(businessId: string): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.getAllParties,
    options: {
      payload: { businessId },
    },
  }) as Promise<T>
}

export function getUserParties<T>(
  businessId: string,
  memberId: string
): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.getUserParties,
    options: {
      payload: { businessId, memberId },
    },
  }) as Promise<T>
}

export type PdfColumns = {
  remark: boolean
  note: boolean
  party: boolean
  member: boolean
  category: boolean
  date?: boolean
  expenses?: boolean
  refunds?: boolean
  collections?: boolean
}

export type DownloadBusinessTransactionsReportPayload = {
  businessId: string
  reportType: "pdf" | "csv"
  memberId?: string
  from_datetime?: string
  to_datetime?: string
  partyId?: string
  categoryId?: string
  status?: "SUCCESS" | "PENDING" | "REFUNDED"
  attachments?: "attached" | "missing"
  paymentCollectionsEnabled?: boolean
  columns?: PdfColumns
}

export function downloadBusinessTransactions<T>({
  from_datetime,
  to_datetime,
  status,
  memberId,
  businessId,
  paymentCollectionsEnabled,
  partyId,
  reportType,
  attachments,
  columns,
  categoryId,
  ...rest
}: DownloadBusinessTransactionsReportPayload & ApiConfig): Promise<T> {
  const payload: DownloadBusinessTransactionsReportPayload = {
    businessId,
    reportType,
    paymentCollectionsEnabled,
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  if (status) {
    payload["status"] = status
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  if (partyId) {
    payload["partyId"] = partyId
  }
  if (attachments) {
    payload["attachments"] = attachments
  }
  if (columns) {
    payload["columns"] = columns
  }
  if (categoryId) {
    payload["categoryId"] = categoryId
  }

  return callApiService({
    method: "GET",
    endpoint: endpoints.downloadBusinessTransactionsReport,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

type GetTransactionAttachmetUrl = {
  id: number
}
export function getTransactionAttachmentUrl<T>(
  payload: GetTransactionAttachmetUrl
): Promise<T> {
  return callApiService({
    method: "POST",
    endpoint: endpoints.getAttachmentUrl,
    options: {
      payload,
    },
  }) as Promise<T>
}

type GetTransactionAttachments = {
  npciTransactionId: string
}
export function getTransactionAttachments<T>(
  payload: GetTransactionAttachments
): Promise<T> {
  return callApiService({
    method: "POST",
    endpoint: endpoints.getTransactionAttachments,
    options: {
      payload,
    },
  }) as Promise<T>
}

type GetTransactionDetailsPayload = {
  businessId: string
  transactionId: string
}
export function getTransactionDetails<T>({
  businessId,
  transactionId,
  ...rest
}: GetTransactionDetailsPayload & ApiConfig): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.transactionDetails,
    options: {
      payload: { businessId, transactionId },
      ...rest,
    },
  }) as Promise<T>
}

type AddTransactionAttachment = {
  fileName: string
  mimeType: string
  npciTransactionId: string
  fileBase64: string
}
export function addTransactionAttachment<T>({
  fileName,
  mimeType,
  npciTransactionId,
  fileBase64,
  ...rest
}: AddTransactionAttachment & ApiConfig): Promise<T> {
  return callApiService({
    method: "POST",
    endpoint: endpoints.addAttachment,
    options: {
      payload: { mimeType, fileName, fileBase64, npciTransactionId },
      ...rest,
    },
  }) as Promise<T>
}

export function deleteTransactionAttachment<T>({
  id,
  ...rest
}: { id: number } & ApiConfig) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.deleteAttachment,
    options: {
      payload: { id },
      ...rest,
    },
  }) as Promise<T>
}

export function getUserJourney<T>({
  businessId,
  ...rest
}: { businessId: string } & ApiConfig) {
  return callApiService({
    method: "GET",
    endpoint: endpoints.userJourney,
    options: {
      payload: { businessId },
      ...rest,
      version: "v3",
    },
  }) as Promise<T>
}

export type UserProfilePayload = {
  businessId: string
  memberId?: string
}
export function getUserProfile<T>({
  businessId,
  memberId,
  ...rest
}: UserProfilePayload & ApiConfig) {
  const payload: UserProfilePayload = {
    businessId,
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.userProfile,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type WalletSummaryPayload = {
  businessId: string
  memberId: string
  from_datetime?: string
  to_datetime?: string
}

export function getUserWalletSummary<T>({
  businessId,
  memberId,
  from_datetime,
  to_datetime,
  ...rest
}: WalletSummaryPayload & ApiConfig) {
  const payload: WalletSummaryPayload = {
    businessId,
    memberId,
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.walletSummary,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type WalletStatusFilterTypes =
  | "FAILURE"
  | "PENDING"
  | "SUCCESS"
  | "REJECTED"
  | "EXPIRED"
  | "REQUESTED"
  | "DEEMED"
  | "ALL"
  | "REFUNDED"
export type WalletTransactionsPayload = {
  businessId: string
  memberId?: string
  from_datetime?: string
  to_datetime?: string
  category?: "B2C" | "EXPENSES" | "COLLECTIONS" | "C2B"
  businessUserId?: string
  status?: WalletStatusFilterTypes
  partyId?: string
  attachments?: "attached" | "missing"
  skip: number
  take: number
  categoryId?: string
}

export function getUserWalletTransactions<T>({
  businessId,
  memberId,
  skip,
  take,
  category,
  from_datetime,
  to_datetime,
  businessUserId,
  status,
  partyId,
  attachments,
  categoryId,
  ...rest
}: WalletTransactionsPayload & ApiConfig) {
  const payload: WalletTransactionsPayload = {
    businessId,
    memberId,
    skip,
    take,
  }
  if (category) {
    payload["category"] = category
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  if (businessUserId) {
    payload["businessUserId"] = businessUserId
  }
  if (status) {
    payload["status"] = status
  }
  if (partyId) {
    payload["partyId"] = partyId
  }
  if (attachments) {
    payload["attachments"] = attachments
  }
  if (categoryId) {
    payload["categoryId"] = categoryId
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.walletTransactions,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export function getAllWalletBalances<T>({
  businessId,
  ...rest
}: { businessId: string } & ApiConfig) {
  const payload = {
    businessId,
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.allWalletBalances,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export function getDeactivatedWallets<T>({
  businessId,
  ...rest
}: { businessId: string } & ApiConfig) {
  const payload = {
    businessId,
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.deactivatedWallets,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export const checkIfPaymentsActiveInAnyBusiness = <T>(
  payload: {
    businessId: string
    memberId?: string
  },
  options: ApiConfig = {}
) => {
  return callApiService({
    method: "GET",
    endpoint: endpoints.checkWalletEnabled,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export type CopyTransactionsToCashbookPayload = {
  businessId: string
  npciTransactionIds: string[]
  destBookId: string
  data?: Record<string, TTransaction>
}

export function copyTransactionsToCashbook<T>(
  requestPayload: CopyTransactionsToCashbookPayload,
  options: ApiConfig = {}
) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.copyTransactions,
    options: {
      payload: requestPayload,
      ...options,
    },
  }) as Promise<T>
}

export type UpdateTransactionRemarkPayload = {
  businessId: string
  npciTransactionId: string
  note: string
}

export function updateTransactionRemark<T>(
  payload: UpdateTransactionRemarkPayload,
  options: ApiConfig = {}
) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.updateTransactionDetails,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export function getPaymentCategoryDetails<T>(
  payload: {
    businessId: string
  },
  options: ApiConfig = {}
) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.manageCategory,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export type PauseUnpauseWalletPayload = {
  businessId: string
  action: "PAUSE" | "UNPAUSE"
  memberId: string
}

export function pauseUnpauseWallet<T>(
  payload: PauseUnpauseWalletPayload,
  options: ApiConfig = {}
) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.pauseUnpauseWallet,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}
export function getSubscriptionPayments<T>(
  payload: {
    businessId: string
  },
  options: ApiConfig = {}
) {
  return callApiService({
    method: "GET",
    endpoint: endpoints.getSubscriptionPayments,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export function toggleCategoryState<T>(
  payload: {
    businessId: string
    disabled?: boolean
    required?: boolean
  },
  options: ApiConfig = {}
) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.manageCategory,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

type UploadBkycDocumentViaUrlPayloadType = {
  businessId: string
  fileName: string
  mimeType: string
  multiple: boolean
  type: string
  fileBase64: string
}

export function uploadBkycDocumentViaUrl<T>(
  payload: UploadBkycDocumentViaUrlPayloadType,
  key: string,
  options: ApiConfig = { isAuthenticatedRequest: false }
) {
  return callApiService({
    method: "POST",
    endpoint: `${endpoints.uploadBkycDocumentViaUrl}?key=${key}`,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export function getPaymentCategories<T>(
  businessId: string,
  options: ApiConfig = {}
) {
  return callApiService({
    method: "GET",
    endpoint: endpoints.categoryList,
    options: {
      payload: {
        businessId,
      },
      ...options,
    },
  }) as Promise<T>
}

export function enableDisableSingleCategory<T>(
  payload: {
    businessId: string
    action: "DISABLE" | "ENABLE" // ENABLE | DISABLE
    categoryId?: string
    defaultCategoryId?: string
  },
  options: ApiConfig = {}
) {
  const categoryIdPayload: {
    categoryId?: string | null
    defaultCategoryId?: string
  } = {}
  if (payload.categoryId) {
    categoryIdPayload["categoryId"] = payload.categoryId
  } else if (payload.defaultCategoryId) {
    categoryIdPayload["defaultCategoryId"] = payload.defaultCategoryId
  }
  return callApiService({
    method: "POST",
    endpoint: endpoints.toggleSingleCategory,
    options: {
      payload: {
        businessId: payload.businessId,
        action: payload.action,
        ...categoryIdPayload,
      },
      ...options,
    },
  }) as Promise<T>
}

export function addNewCategory<T>(
  payload: {
    businessId: string
    name: string
  },
  options: ApiConfig = {}
) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.category,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}
type FetchBkycDocumentViaUrlPayloadType = {
  businessId: string
  type: string[]
}

export function fetchBkycDocumentViaUrl<T>(
  payload: FetchBkycDocumentViaUrlPayloadType,
  key: string,
  options: ApiConfig = { isAuthenticatedRequest: false }
) {
  return callApiService({
    method: "POST",
    endpoint: `${endpoints.fetchUploadedBkycDocumentViaUrl}?key=${key}`,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export function updateCategory<T>(
  payload: {
    businessId: string
    name: string
    id: string
  },
  options: ApiConfig = {}
) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.updateCategory,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

type DeleteBkycDocumentViaUrlPayloadType = {
  businessId: string
  id: number
}

export function deleteBkycDocumentViaUrl<T>(
  payload: DeleteBkycDocumentViaUrlPayloadType,
  key: string,
  options: ApiConfig = { isAuthenticatedRequest: false }
) {
  return callApiService({
    method: "POST",
    endpoint: `${endpoints.deleteUploadedBkycDocumentViaUrl}?key=${key}`,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export function assignCategory<T>(
  payload: {
    businessId: string
    categoryId?: string
    defaultCategoryId?: string
    npciTransactionIds: string[]
  },
  options: ApiConfig = {}
) {
  const categoryIdPayload: {
    categoryId?: string | null
    defaultCategoryId?: string
  } = {}
  if (payload.categoryId) {
    categoryIdPayload["categoryId"] = payload.categoryId
  } else if (payload.defaultCategoryId) {
    categoryIdPayload["defaultCategoryId"] = payload.defaultCategoryId
  } else {
    categoryIdPayload["categoryId"] = null
  }
  return callApiService({
    method: "POST",
    endpoint: endpoints.assignCategory,
    options: {
      payload: {
        businessId: payload.businessId,
        npciTransactionIds: payload.npciTransactionIds,
        ...categoryIdPayload,
      },
      ...options,
    },
  }) as Promise<T>
}

export function deleteCategory<T>(
  payload: {
    businessId: string
    id: string
    categoryId?: string
    defaultCategoryId?: string
  },
  options: ApiConfig = {}
) {
  const categoryIdPayload: {
    categoryId?: string | null
    defaultCategoryId?: string
  } = {}
  if (payload.categoryId) {
    categoryIdPayload["categoryId"] = payload.categoryId
  } else if (payload.defaultCategoryId) {
    categoryIdPayload["defaultCategoryId"] = payload.defaultCategoryId
  }
  return callApiService({
    method: "POST",
    endpoint: endpoints.deleteCategory,
    options: {
      payload: {
        businessId: payload.businessId,
        id: payload.id,
        ...categoryIdPayload,
      },
      ...options,
    },
  }) as Promise<T>
}

type MarkBkycDocumentViaUrlPayloadType = {
  businessId: string
}

export function markBkycDocumentUploadDoneViaUrl<T>(
  payload: MarkBkycDocumentViaUrlPayloadType,
  key: string,
  options: ApiConfig = { isAuthenticatedRequest: false }
) {
  return callApiService({
    method: "POST",
    endpoint: `${endpoints.markBkycDocumentUploadDoneViaUrl}?key=${key}`,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}
export function getSubscriptionWalletCount<T>(
  payload: { businessId: string },
  options: ApiConfig = {}
) {
  return callApiService({
    method: "GET",
    endpoint: endpoints.getSubscriptionWalletCount,
    options: { payload, ...options },
  }) as Promise<T>
}

export type StaffWalletReportType = "pdf" | "csv"

export type StaffWalletTransactionsReportPayload = {
  businessId: string
  memberId: string
  reportType: StaffWalletReportType
}

export function getStaffWalletTransactionsReport<T>(
  payload: StaffWalletTransactionsReportPayload,
  options: ApiConfig = {}
): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.staffWalletTransactionsReport,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export function getStaffWalletSummary<T>(
  payload: { businessId: string; memberId: string },
  options: ApiConfig = {}
): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.staffWalletSummary,
    options: { payload, ...options },
  }) as Promise<T>
}

import { useCallback, useState } from "react"
import { useFunctions } from "reactfire"
import { httpsCallable } from "firebase/functions"
import { convertFileToBase64 } from "@cashbook/web-components"
import toast from "react-hot-toast"

/**
 * Returns a function to upload a file to cloud storage
 */
export function useUploadImageFile() {
  const fns = useFunctions()
  return useCallback(
    async (file: Blob) => {
      const imageBase64String = await convertFileToBase64(file)
      const resp = await httpsCallable<
        { imageBase64String: string },
        { image_url: string; thumb_url: string }
      >(
        fns,
        "uploadImageFile"
      )({
        imageBase64String: imageBase64String,
      })
      return {
        imageUrl: resp.data.image_url,
        thumbUrl: resp.data.thumb_url,
      }
    },
    [fns]
  )
}

export function useUploadEntryAttachment() {
  const fns = useFunctions()
  const uploadAttachment = useCallback(
    async ({
      file,
      name,
      mimeType,
      signedUrl = true,
    }: {
      file: Blob
      name: string
      mimeType: string
      signedUrl?: boolean
    }) => {
      const imageBase64String = await convertFileToBase64(file)
      const { data } = await httpsCallable<
        {
          fileBase64String: string
          fileName: string
          mimeType: string
          signedUrl: boolean
        },
        {
          fileName: string
          id: string
          mimeType: string
          thumbUrl: string | null
          url: string
        }
      >(
        fns,
        "addTransactionAttachment"
      )({
        fileBase64String: imageBase64String,
        fileName: name,
        mimeType: mimeType,
        signedUrl,
      })
      return data
    },
    [fns]
  )

  const deleteAttachment = useCallback(
    async (id: string, bookId: string, transactionId: string) => {
      try {
        await httpsCallable<{
          id: string
          bookId: string
          transactionId: string
        }>(
          fns,
          "deleteTransactionAttachment"
        )({
          id,
          bookId,
          transactionId,
        })
      } catch {
        return
      }
    },
    [fns]
  )

  return {
    uploadAttachment,
    deleteAttachment,
  }
}

export function useListEntryAttachments() {
  const fns = useFunctions()
  const getEntryAttachments = useCallback(
    async ({
      bookId,
      transactionId,
    }: {
      bookId: string
      transactionId: string
    }) => {
      const { data } = await httpsCallable<{
        bookId: string
        transactionId: string
      }>(
        fns,
        "getSignedUrlForTransactionAttachment"
      )({ bookId, transactionId })
      return data
    },
    [fns]
  )

  return {
    getEntryAttachments,
  }
}

export function setSyncStoredItem(key: string, value: unknown) {
  try {
    return localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    return
  }
}

export function getSyncStoredItem(key: string) {
  try {
    const storedItem = localStorage.getItem(key) || ""
    return JSON.parse(storedItem)
  } catch (e) {
    return
  }
}

export function removeSyncStoredItem(key: string) {
  try {
    return localStorage.removeItem(key)
  } catch (e) {
    return
  }
}

export function useSyncedStorageState<T>(
  key: string,
  defaultValue: T
): [state: T, setState: (state: T) => void] {
  const [state, setState] = useState<T>(() => {
    try {
      const savedPreferences = localStorage.getItem(key)
      if (!savedPreferences) return defaultValue
      return JSON.parse(savedPreferences)
    } catch (e) {
      return defaultValue
    }
  })
  const updateState = useCallback(
    (state: T) => {
      setState(state)
      try {
        localStorage.setItem(key, JSON.stringify(state))
      } catch (e) {
        toast.error(
          "Your preferences cannot be saved because of some missing permissions."
        )
      }
    },
    [key]
  )
  return [state, updateState]
}

type UserPaymentsProfile = {
  uid: string
  name: string
  upi?: {
    address: string
    id: string
    disabled?: boolean
    primary?: boolean
  }
  kycStatus: string
  balance?: number
  fullName?: string
  businessId: string
  entityId: string
}

let userPaymentsProfile: UserPaymentsProfile | undefined = undefined
const userPaymentsProfileSubscribers = new Set()
export const userPaymentsProfileStore = {
  getUserProfile() {
    const storedState = sessionStorage.getItem("userPaymentsProfileStore")
    if (
      storedState !== undefined &&
      storedState !== null &&
      storedState !== "undefined" &&
      storedState !== "null"
    ) {
      return JSON.parse(storedState)
    }
    return userPaymentsProfile
  },
  subscribe(callback: () => void) {
    userPaymentsProfileSubscribers.add(callback)
    return () => userPaymentsProfileSubscribers.delete(callback)
  },
  updateProfile(value: UserPaymentsProfile) {
    userPaymentsProfile = value
    sessionStorage.setItem(
      "userPaymentsProfileStore",
      JSON.stringify(userPaymentsProfile)
    )
    userPaymentsProfileSubscribers.forEach((callback) => {
      ;(callback as () => void)()
    })
  },
  deleteProfile() {
    userPaymentsProfile = undefined
    sessionStorage.setItem(
      "userPaymentsProfileStore",
      JSON.stringify(userPaymentsProfile)
    )
    userPaymentsProfileSubscribers.clear()
  },
}

type VkycStatus = {
  attemptsRemaining?: boolean
  completed?: boolean
}

type Plan = {
  created_at: string
  frequency: string
  id: string
  name: string
  price: unknown
  updated_at: string
}

export type SubscriptionDetails = {
  autopayPgSubscription?: null
  autopay_pg_subscription_id: string
  billing_address: string
  billing_amount: string
  billing_email: string
  business_id: string
  business_name: string | null
  coupon: string | null
  coupon_applied_at: string | null
  coupon_applied_in_cycle: string | null
  coupon_id: string | null
  created_at: string
  discounted_amount: string | null
  failure_reason: string | null
  gst_amount: string | null
  gst_number: string | null
  id: string
  last_payment_date: string
  last_payment_tried_at: string
  next_billing_date: string
  next_payment_raised: boolean
  payment_due_date: string
  payment_method: string
  payment_status: "PENDING" | "SUCCESS" | "FAILED"
  plan: Plan
  plan_id: string
  status: "ACTIVE" | "INACTIVE"
  updated_at: string
  wallet_quantity: number
  next_billing_amount: string
}

export type UserJourney = {
  role: "owner" | "staff" | "partner"
  business_kyc_status?: "APPROVED" | "PENDING" | "REDO" | "REJECTED"
  business_kyc_remarks?: string
  corpShortName?: string
  is_payments_enabled?: boolean
  min_kyc: boolean
  can_join_waitlist?: boolean
  wallet_issued?: number
  va_txn_done?: boolean
  can_enable_payments?: boolean
  can_enable_wallet?: boolean
  vkyc: VkycStatus
  show_payments_tab?: boolean
  is_card_user?: boolean
  card_payments_status?: "DEACTIVATING"
  subscription?: SubscriptionDetails
}

// Initialize showPayments from sessionStorage if available
let showPayments: { [key: string]: UserJourney } = (() => {
  try {
    const storedState = sessionStorage.getItem("showPaymentsStore")
    return storedState ? JSON.parse(storedState) : {}
  } catch {
    return {}
  }
})()

const showPaymentsSubscribers = new Set()

export const showPaymentsStore = {
  getShowPaymentsList() {
    return showPayments
  },
  subscribe(callback: () => void) {
    showPaymentsSubscribers.add(callback)
    return () => showPaymentsSubscribers.delete(callback)
  },
  updateList(id: string, value: UserJourney) {
    const newPayments = { ...showPayments, [id]: value }
    if (JSON.stringify(newPayments) !== JSON.stringify(showPayments)) {
      showPayments = newPayments
      try {
        sessionStorage.setItem(
          "showPaymentsStore",
          JSON.stringify(showPayments)
        )
        showPaymentsSubscribers.forEach((callback) => {
          ;(callback as () => void)()
        })
      } catch (error) {
        console.error("Failed to update showPaymentsStore:", error)
      }
    }
  },
  deleteStore() {
    showPayments = {}
    showPaymentsSubscribers.clear()
    try {
      sessionStorage.removeItem("showPaymentsStore")
    } catch (error) {
      console.error("Failed to delete showPaymentsStore:", error)
    }
  },
}

export type CategorySettings = {
  disabled: boolean
  required: boolean
}

let categorySettings: { [key: string]: CategorySettings } = {}
const categorySettingsSubscribers = new Set()

export const categorySettingsStore = {
  getCategorySettings() {
    return categorySettings
  },
  subscribe(callback: () => void) {
    categorySettingsSubscribers.add(callback)
    return () => categorySettingsSubscribers.delete(callback)
  },
  updateList(id: string, value: CategorySettings) {
    categorySettings = { ...categorySettings, [id]: value }
    categorySettingsSubscribers.forEach((callback) => {
      ;(callback as () => void)()
    })
  },
  deleteStore() {
    categorySettingsSubscribers.clear()
  },
}

export type PaymentCategoryItem = {
  id: string | null
  name: string
  mcc: string[]
  tag_count: number
  default_id: string | null
  enabled?: boolean
}

let categoryList: { [key: string]: PaymentCategoryItem[] } = {}
const categoryListSubscribers = new Set()

export const categoryListStore = {
  getCategorySettings() {
    return categoryList
  },
  subscribe(callback: () => void) {
    categoryListSubscribers.add(callback)
    return () => categoryListSubscribers.delete(callback)
  },
  updateList(id: string, value: PaymentCategoryItem[]) {
    categoryList = { ...categoryList, [id]: value }
    categoryListSubscribers.forEach((callback) => {
      ;(callback as () => void)()
    })
  },
  deleteStore() {
    categoryListSubscribers.clear()
  },
}

type UserWallet = {
  uid: string
  name: string
  phoneNumber: string
  kycStatus: "init" | "min_kyc" | "full_kyc"
  balance?: number
  walletId?: string
  deactivation?: "init" | "completed"
}

let userWallet: UserWallet
const userWalletSubscribers = new Set()
export const userWalletStore = {
  getUserWallet(): UserWallet {
    const storedState = sessionStorage.getItem("userWalletStore")
    if (storedState) {
      return JSON.parse(storedState)
    }
    return userWallet
  },
  subscribe(callback: () => void) {
    userWalletSubscribers.add(callback)
    return () => userWalletSubscribers.delete(callback)
  },
  setUserWallet(wallet: UserWallet) {
    userWallet = wallet
    sessionStorage.setItem("userWalletStore", JSON.stringify(wallet))
    userWalletSubscribers.forEach((callback) => {
      ;(callback as () => void)()
    })
  },
  deleteStore() {
    userWalletSubscribers.clear()
    sessionStorage.removeItem("userWalletStore")
  },
}

let deactivation: "init" | "completed" | ""
const deactivationSubscribers = new Set()
export const deactivatedWalletStore = {
  getIsWalletDeactivated() {
    const storedState = sessionStorage.getItem("deactivatedWalletStore")
    if (storedState) {
      return JSON.parse(storedState)
    }
    return deactivation
  },
  subscribe(callback: () => void) {
    deactivationSubscribers.add(callback)
    return () => deactivationSubscribers.delete(callback)
  },
  setDeactivatedWallet(value?: "" | "init" | "completed") {
    deactivation = value || ""
    sessionStorage.setItem(
      "deactivatedWalletStore",
      JSON.stringify(deactivation)
    )
    deactivationSubscribers.forEach((callback) => {
      ;(callback as () => void)()
    })
  },
  deleteStore() {
    deactivationSubscribers.clear()
  },
}

export function clearExternalStorage() {
  showPaymentsStore.deleteStore()
  deactivatedWalletStore.deleteStore()
  userWalletStore.deleteStore()
  userPaymentsProfileStore.deleteProfile()
}

export type SubscriptionInvoice = {
  amount: number
  created_at: string
  cycle: number
  discount: number
  ext_txn_id: string
  gst: number
  id: string
  method: string
  pg_payment_id: number
  pg_subscription_id: number
  receipt: string
  remarks: string
  status: string
  subscription_id: string
  total_amount: number
  updated_at: string
  details: {
    wallet_quantity: number
  }
}

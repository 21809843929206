/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Box,
  CBButton,
  checkFileSize,
  DocumentDownloadIcon,
  ErrorIcon,
  formikOnSubmitWithErrorHandling,
  Heading,
  Inline,
  LogoIcon,
  Modal,
  ModalBody,
  ModalFooter,
  SpinnerIcon,
  Stack,
  Text,
  useOverlayTriggerState,
  WhatsAppIcon,
} from "@cashbook/web-components"
import config from "../config"
import { useSearchParams } from "react-router-dom"
import { FormMultipleFilesField } from "../Transactions/Log"
import { Form, Formik } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  fileToBase64,
  getMimeTypeFromBase64,
  getWhatsAppSharingLink,
} from "@cashbook/util-general"
import {
  BACKEND_STATUS_CODES,
  deleteBkycDocumentViaUrl,
  fetchBkycDocumentViaUrl,
  markBkycDocumentUploadDoneViaUrl,
  uploadBkycDocumentViaUrl,
} from "@cashbook/data-store/payments"
import toast from "react-hot-toast"
import { EntryAttachmentInfo } from "@cashbook/data-store/books"

// Define Business Registration Types
enum BusinessRegistrationType {
  PVT_LTD = "privateLimited",
  FIRM = "partnership/LLP",
  TRUST = "trust/Foundation",
  SOLE_PROP = "soleProprietorship",
  BODY_OF_INDIVIDUALS = "BOI/AOP",
}

const checkFileSizeUnderLimit = checkFileSize(2000)

const handleErrorResponse = (
  httpError: { statusCode: number; message: string | string[]; error: string },
  handleExpiryCallback: () => void,
  genericErrorMessage: string
) => {
  if (
    httpError?.statusCode === BACKEND_STATUS_CODES.UNAUTHORIZED ||
    httpError?.statusCode === BACKEND_STATUS_CODES.UNPROCESSABLE_ENTITY
  ) {
    handleExpiryCallback()
  } else if (httpError?.statusCode === BACKEND_STATUS_CODES.BAD_REQUEST) {
    if (Array.isArray(httpError.message) && httpError.message.length > 0) {
      toast.error(httpError.message[0])
    } else {
      if (typeof httpError.message === "string") {
        toast.error(httpError.message)
      } else {
        toast.error(genericErrorMessage)
      }
    }
  } else if (
    httpError?.message === "No business found with provided businessId"
  ) {
    toast.error("No business found with provided businessId")
  } else {
    toast.error(genericErrorMessage)
  }
}

// Define document structure
interface DocumentRequirement {
  key: string
  label: string
  maxFiles?: number
  templateLink?: string
  mandatory: boolean
}

// Define the mapping of documents required for each business type
const documentsRequired: Record<string, DocumentRequirement[]> = {
  [BusinessRegistrationType.PVT_LTD]: [
    {
      key: "bkyc_incorporation_certificate",
      label: "Certificate of Incorporation",
      mandatory: true,
    },
    { key: "bkyc_gst_certificate", label: "GST Certificate", mandatory: true },
    {
      key: "bkyc_pan_card_company",
      label: "PAN Card of the Company",
      mandatory: true,
    },
    {
      key: "bkyc_moa_memo",
      label: "MoA Memorandum of Association",
      mandatory: true,
    },
    {
      key: "bkyc_association_article",
      label: "AoA Article of Association",
      mandatory: true,
    },
    {
      key: "bkyc_director_details",
      label: "Director’s Details - Identity Proof & Address Proof",
      maxFiles: 2,
      mandatory: true,
    },
    {
      key: "bkyc_account_opening_board_resolution",
      label: "Board Resolution Authorizing Account Opening",
      templateLink:
        "https://static.cashbook.in/assets/Board%20Resolution%20Pvt%20Ltd%20LLP.docx",
      mandatory: false,
    },
  ],
  [BusinessRegistrationType.FIRM]: [
    {
      key: "bkyc_registration_certificate",
      label: "Registration Certificate",
      mandatory: true,
    },
    { key: "bkyc_gst_certificate", label: "GST Certificate", mandatory: true },
    {
      key: "bkyc_pan_card_company",
      label: "PAN Card of the Company",
      mandatory: true,
    },
    {
      key: "bkyc_partnership_deed_or_agreement",
      label: "Partnership Deed/Firm Agreement",
      mandatory: true,
    },
    {
      key: "bkyc_partner_details",
      label: "Partner’s Details - Identity Proof & Address Proof",
      mandatory: true,
    },
    {
      key: "bkyc_account_opening_authorization_letter",
      label: "Resolution/Authorization Letter",
      templateLink:
        "https://static.cashbook.in/assets/Authhorization%20Letter.docx",
      mandatory: false,
    },
  ],
  [BusinessRegistrationType.TRUST]: [
    {
      key: "bkyc_trust_registration_certificate",
      label: "Registration Certificate",
      mandatory: true,
    },
    { key: "bkyc_trust_deed", label: "Trust Deed", mandatory: true },
    {
      key: "bkyc_pan_card_trust",
      label: "PAN Card of the Trust",
      mandatory: true,
    },
    {
      key: "bkyc_power_of_attorney",
      label: "Power of Attorney",
      mandatory: true,
    },
    {
      key: "bkyc_document_establishing_legal_existence",
      label: "Document Establishing Legal Existence",
      mandatory: true,
    },
    {
      key: "bkyc_trustee_details",
      label: "Trustee’s Details - Identity & Address Proof",
      mandatory: true,
    },
    {
      key: "bkyc_resolution_of_managing_body",
      label: "Resolution of Managing Body",
      templateLink:
        "https://static.cashbook.in/assets/Board%20Resolution%20Trust.docx",
      mandatory: false,
    },
  ],
  [BusinessRegistrationType.BODY_OF_INDIVIDUALS]: [
    {
      key: "bkyc_boi_or_aop_pan",
      label: "AOP/BOI PAN Card",
      mandatory: true,
    },
    {
      key: "bkyc_power_of_attorney",
      label: "Power of Attorney",
      mandatory: true,
    },
    {
      key: "bkyc_poa_holder_details",
      label: "Power of Attorney’s Details - Identity & Address Proof",
      maxFiles: 2,
      mandatory: true,
    },
    {
      key: "bkyc_boi_or_aop_resolution_of_managing_body",
      label: "Resolution of Managing Body",
      mandatory: false,
      templateLink: "https://static.cashbook.in/assets/AOP_BOI_Resolution.docx",
    },
  ],
  [BusinessRegistrationType.SOLE_PROP]: [], // No documents required
}

const BusinessKycDocumentsUpload: React.FC = () => {
  const [searchParams] = useSearchParams()
  const [isExpired, setIsExpired] = useState(false)
  const [isLinkBroken, setIsLinkBroken] = useState(false)
  const businessName = searchParams.get("businessName") || ""

  useEffect(() => {
    const expiresAtParam = searchParams.get("expires_at")
    if (expiresAtParam) {
      const expirationDate = new Date(expiresAtParam)
      const currentDate = new Date()

      if (currentDate >= expirationDate) {
        setIsExpired(true)
      }
    }
  }, [searchParams])

  useEffect(() => {
    document.title = "Upload Business Documents"
  }, [])

  useEffect(() => {
    const apiKey = searchParams.get("key")
    const businessId = searchParams.get("businessId")
    const expirationKey = searchParams.get("expires_at")
    const businessRegType = searchParams.get("businessRegType")
    const businessName = searchParams.get("businessName")

    if (
      apiKey &&
      businessId &&
      expirationKey &&
      businessRegType &&
      businessName
    ) {
      if (!Object.keys(documentsRequired).includes(businessRegType)) {
        setIsLinkBroken(true)
      } else {
        setIsLinkBroken(false)
      }
    } else {
      setIsLinkBroken(true)
    }
  }, [searchParams])

  const handleSupportClick = () => {
    const link = getWhatsAppSharingLink({
      phoneNumber: config.supportPhoneNumber,
      text: `Hi, I need help with document upload. Business Name - ${businessName}`,
    })
    window.open(link, "_blank")
  }

  return (
    <Box backgroundColor="surfaceDefault" className="h-screen overflow-auto">
      <Inline
        borderBottomWidth="1"
        zIndex="10"
        bgColor="white"
        width="full"
        alignItems="center"
        justifyContent="center"
        className="shadow sticky top-0"
      >
        <Box bgColor="surfaceDefault" maxWidth="screen2xl" width="full">
          <Inline
            justifyContent="between"
            alignItems="stretch"
            paddingX="4"
            paddingY="2"
          >
            <Inline alignItems="center" className="flex items-center">
              <Heading as="h1" fontSize="base" fontWeight="medium">
                <Inline alignItems="center">
                  <LogoIcon size="8" color="blue900" />
                  <Text
                    as="span"
                    fontSize="md"
                    fontWeight="semibold"
                    color="blue900"
                  >
                    {config.appTitle.toUpperCase()}
                  </Text>
                </Inline>
              </Heading>
            </Inline>
            <CBButton
              level="secondary"
              status="cashIn"
              iconPlacement="left"
              onClick={handleSupportClick}
            >
              <WhatsAppIcon />
              Contact Us
            </CBButton>
          </Inline>
        </Box>
      </Inline>
      <Box paddingX="4" className="pb-10 overflow-scroll">
        <Stack marginTop="6">
          <Text fontSize="h4">Upload Business Documents</Text>
        </Stack>
        {isLinkBroken ? (
          <Box width="auto">
            <Box className="w-[240px]">
              <Alert status="error" marginTop="6" className="w-[206px]">
                <Inline alignItems="center" gap="3">
                  <ErrorIcon color="iconError" />
                  This link is Broken.
                </Inline>
              </Alert>
            </Box>
            <Text>
              Please ask admin to share link to upload business documents.
            </Text>
          </Box>
        ) : isExpired ? (
          <Box width="auto">
            <Box className="w-[440px]">
              <Alert status="warning" marginTop="6" className="w-[372px]">
                This document upload link has been expired.
              </Alert>
            </Box>
            <Text>
              Please ask admin to Regenerate & Share new link to upload business
              documents.
            </Text>
          </Box>
        ) : (
          <>
            <Stack marginTop="6">
              <Text fontSize="b2">Business Name</Text>
            </Stack>
            <Stack marginTop="2">
              <Text fontSize="s1">{businessName}</Text>
            </Stack>
            <Stack marginTop="6">
              <Text fontSize="s3">
                Please add the following business documents
              </Text>
            </Stack>
            <Inline marginTop="4">
              <UploadDocumentsForm
                businessId={searchParams.get("businessId") || ""}
                apiKey={searchParams.get("key") || ""}
                setLinkExpired={(isExpired) => {
                  setIsExpired(isExpired)
                }}
              />
            </Inline>
          </>
        )}
      </Box>
    </Box>
  )
}

type Props = {
  businessId: string
  apiKey: string
  setLinkExpired: (isExpired: boolean) => void
}

const UploadDocumentsForm: React.FC<Props> = ({
  businessId,
  apiKey,
  setLinkExpired,
}) => {
  const [searchParams] = useSearchParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fetchAttachmentsLoading, setFetchAttachmentsLoading] = useState(true)
  const [attachments, setAttachments] = useState<
    | {
        [id: string]: EntryAttachmentInfo[]
      }
    | undefined
  >()

  const businessRegType: string = searchParams.get("businessRegType") || ""

  const noDocumentsUploaded = useMemo(() => {
    if (!attachments || Object.keys(attachments).length === 0) {
      return true
    }
    let noDocumentsUploaded = true
    attachments &&
      Object.values(attachments).forEach((attachmentArray) => {
        if (attachmentArray.length > 0) {
          noDocumentsUploaded = false
        }
      })
    return noDocumentsUploaded
  }, [attachments])

  const fetchUploadedFiles = useCallback(async () => {
    try {
      const requiredDocuments: string[] = []
      documentsRequired[businessRegType] &&
        Object.values(documentsRequired[businessRegType]).forEach(
          (document: DocumentRequirement) => {
            requiredDocuments.push(document.key)
          }
        )
      const response: any = await fetchBkycDocumentViaUrl(
        { businessId, type: requiredDocuments },
        apiKey
      )
      if (response && response.data) {
        const allAttachments:
          | {
              [id: string]: EntryAttachmentInfo[]
            }
          | undefined = {}
        Object.keys(response.data).forEach((key) => {
          if (response.data[key]?.length) {
            const attachments: EntryAttachmentInfo[] = []
            response.data[key].forEach((attachmentItem: any) => {
              attachments.push({
                id: attachmentItem.id,
                fileName: attachmentItem.file_name,
                mimeType: attachmentItem.mime_type,
                url: attachmentItem.url,
              })
              allAttachments[key] = attachments
            })
          } else {
            allAttachments[key] = []
          }
        })
        setAttachments(allAttachments)
      }
      setFetchAttachmentsLoading(false)
    } catch (error: any) {
      if (
        error?.statusCode === BACKEND_STATUS_CODES.UNAUTHORIZED ||
        error?.statusCode === BACKEND_STATUS_CODES.UNPROCESSABLE_ENTITY
      ) {
        setLinkExpired(true)
      } else if (
        error?.message === "No business found with provided businessId"
      ) {
        toast.error("No business found with provided businessId")
      }
      setFetchAttachmentsLoading(false)
    }
  }, [apiKey, businessId, businessRegType, setLinkExpired])

  useEffect(() => {
    fetchUploadedFiles()
  }, [fetchUploadedFiles])

  const handleSubmitNotification = async (onSuccess: () => void) => {
    setIsSubmitting(true)
    try {
      await markBkycDocumentUploadDoneViaUrl({ businessId }, apiKey)
      onSuccess()
      toast.success("Admin notified successfully!")
    } catch (error: any) {
      handleErrorResponse(
        error,
        () => {
          setLinkExpired(true)
        },
        "Failed to notify admin"
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  if (fetchAttachmentsLoading) {
    return (
      <Stack className="w-full h-[400px] justify-center items-center self-center">
        <SpinnerIcon size="10" />
      </Stack>
    )
  }

  return (
    <Box className="pb-6 bg-white rounded-md w-full h-full mx-auto">
      <Inline className="flex flex-wrap gap-4">
        {documentsRequired[
          businessRegType as keyof typeof documentsRequired
        ]?.map((docType) => {
          const fieldAttachments:
            | { [key: string]: EntryAttachmentInfo }
            | undefined = {}
          if (attachments && attachments[docType.key]?.length) {
            attachments[docType.key].forEach(
              (attachment: EntryAttachmentInfo) => {
                fieldAttachments[attachment.id] = attachment
              }
            )
          }
          return (
            <Box
              key={docType.key}
              className="flex-1 lg:basis-[33.333%] md:basis-[48%] basis-[98%] px-4 pt-4 rounded-md border lg:max-w-[32%] md:max-w-[48%] max-w-[98%]"
              borderColor="borderOutline"
            >
              <Text fontSize="s3">
                {docType.label.replace(/_/g, " ")}
                {docType.mandatory ? (
                  <span className="text-[#C93B3B]">{" *"}</span>
                ) : null}
              </Text>
              <Formik
                initialValues={
                  {
                    bill_file: undefined,
                    attachments: Object.keys(fieldAttachments).length
                      ? fieldAttachments
                      : undefined,
                    operation: undefined,
                    attachmentId: undefined,
                    [docType.key]: undefined,
                  } as any
                }
                onSubmit={formikOnSubmitWithErrorHandling(
                  async (values, actions) => {
                    if (values.operation === "delete" && values.attachmentId) {
                      try {
                        await deleteBkycDocumentViaUrl(
                          { id: Number(values.attachmentId), businessId },
                          apiKey
                        )
                        const updatedAttachments: {
                          [key: string]: EntryAttachmentInfo
                        } = {}
                        const attachmentClones = { ...attachments }
                        const attachmentArray: EntryAttachmentInfo[] = []
                        Object.keys(values.attachments).forEach(
                          (attachmentId) => {
                            if (attachmentId !== values.attachmentId) {
                              updatedAttachments[attachmentId] =
                                values.attachments[attachmentId]
                              attachmentArray.push(
                                values.attachments[attachmentId]
                              )
                            }
                          }
                        )
                        attachmentClones[docType.key] = attachmentArray
                        setAttachments(attachmentClones)
                        actions.setFieldValue(
                          "attachments",
                          Object.keys(updatedAttachments).length
                            ? updatedAttachments
                            : undefined
                        )
                        actions.setFieldValue("attachmentId", undefined)
                      } catch (error: any) {
                        handleErrorResponse(
                          error,
                          () => {
                            setLinkExpired(true)
                          },
                          "Failed to delete file."
                        )
                      }
                      return
                    }

                    if (values[docType.key]) {
                      const file: File = values[docType.key]?.file
                      if (!checkFileSizeUnderLimit(file)) {
                        actions.setFieldError(
                          docType.key,
                          "Please select the file that is less then 2 MB in size"
                        )
                        return
                      }
                      actions.setFieldError(docType.key, undefined)
                      try {
                        const base64String = await fileToBase64(file)
                        const response: any = await uploadBkycDocumentViaUrl(
                          {
                            businessId,
                            fileName: file.name,
                            mimeType:
                              getMimeTypeFromBase64(base64String) || file.type,
                            multiple: Boolean(docType?.maxFiles),
                            type: docType.key,
                            fileBase64: base64String,
                          },
                          apiKey
                        )
                        if (response.data) {
                          const updatedAttachments: {
                            [id: string]: EntryAttachmentInfo
                          } = {
                            ...values.attachments,
                          }
                          updatedAttachments[response.data.id] = {
                            url: response.data.url || "",
                            fileName: response.data.file_name,
                            mimeType: response.data.mime_type || "image/png",
                            id: response.data.id || "",
                          }
                          actions.setFieldValue(docType.key, undefined)
                          actions.setFieldValue(
                            "attachments",
                            updatedAttachments
                          )
                          const attachmentsClone = { ...attachments }
                          const attachmentsArray: EntryAttachmentInfo[] = []
                          Object.values(updatedAttachments).forEach(
                            (attachment) => {
                              attachmentsArray.push(attachment)
                            }
                          )
                          attachmentsClone[docType.key] = attachmentsArray
                          setAttachments(attachmentsClone)
                        }
                        toast.success("File uploaded successfully")
                      } catch (error: any) {
                        if (error?.message === "ENCRYPTED_PDF_NOT_ALLOWED") {
                          toast.error(
                            "Encrypted PDFs are not allowed. Please upload an unprotected PDF file."
                          )
                        } else {
                          handleErrorResponse(
                            error,
                            () => {
                              setLinkExpired(true)
                            },
                            "Failed to upload file."
                          )
                        }
                      }
                    }
                  }
                )}
              >
                {({ values, isSubmitting, setFieldValue, submitForm }) => {
                  return (
                    <Form noValidate>
                      <Inline
                        className="max-w-fit"
                        position="relative"
                        marginTop="4"
                        gap={docType.templateLink ? "2" : "0"}
                      >
                        <FormMultipleFilesField
                          maxFiles={docType.maxFiles || 1}
                          name={docType.key}
                          id={docType.key}
                          from="payments"
                          defaultValues={values.attachments}
                          loading={isSubmitting}
                          onSelectionEnd={() => {
                            setFieldValue("operation", "add")
                            submitForm()
                          }}
                          onRemove={(attachmentId: string) => {
                            setFieldValue("attachmentId", attachmentId)
                            setFieldValue("operation", "delete")
                            submitForm()
                          }}
                          buttonText={
                            docType.maxFiles ? "Upload Files" : "Upload File"
                          }
                          showSubText={false}
                          fileType="businessDoc"
                        />
                        {docType.templateLink ? (
                          <Inline
                            as="a"
                            href={docType.templateLink}
                            alignItems="center"
                            className="h-[40px]"
                            marginLeft="3"
                            gap="1"
                          >
                            <DocumentDownloadIcon color="iconPrimary" />
                            <Text color="textPrimary" fontSize="bt">
                              Download Template
                            </Text>
                          </Inline>
                        ) : null}
                      </Inline>
                    </Form>
                  )
                }}
              </Formik>
            </Box>
          )
        })}
      </Inline>

      {/* Submit Button */}
      <Stack
        justifyContent="start"
        marginTop="6"
        className="w-[342px] sticky bottom-0"
      >
        <NotifyAdminConfirmationModal
          onConfirmNotify={handleSubmitNotification}
          notifyLoading={isSubmitting}
        >
          {({ open }) => (
            <CBButton
              onClick={open}
              disabled={noDocumentsUploaded}
              level="primary"
            >
              Mark complete & Notify admin
            </CBButton>
          )}
        </NotifyAdminConfirmationModal>
      </Stack>
    </Box>
  )
}

export default BusinessKycDocumentsUpload

export function NotifyAdminConfirmationModal({
  children,
  onConfirmNotify,
  notifyLoading,
}: {
  children: (props: { open: () => void }) => React.ReactNode
  onConfirmNotify: (onSuccess: () => void) => void
  notifyLoading: boolean
}) {
  const state = useOverlayTriggerState({})

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Documents Uploaded"
        size="sm"
      >
        <ModalBody>
          <Stack>
            <Text fontSize="b1">
              Please ask the owner to complete the KYB on Mobile App to start
              using UPI wallets for employees.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <CBButton
            type="submit"
            size="lg"
            disabled={notifyLoading}
            loading={notifyLoading}
            onClick={() => {
              onConfirmNotify(state.close)
            }}
          >
            Ok Got It!
          </CBButton>
        </ModalFooter>
      </Modal>
    </>
  )
}
